import { Toolbar, Box } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import ApplicationDrawer from './components/Drawer/Drawer';
import { Route, Routes, useNavigate, } from 'react-router-dom';


//pages
import ArtistPanel from './pages/artistPanel';
import AuthPage from "./pages/auth";
import UserPanel from './pages/usersPanel';
import AuthPanel from './pages/authPanel';
import Profile from './pages/authPanel/profile'
import NewAdmin from './pages/authPanel/newAdmin';
import Form from './pages/authPanel/form'
import PendingPanel from './pages/pendingPanel';
import ArtistProfileSubmission from './pages/pendingPanel/application';
import ResetPassword from './pages/authPanel/resetPassword';
import PendingTransactionPanel from './pages/pendingTransactions';
import MyPendingTransactionPanel from './pages/myPendingTransactions';
import Unauthorized from './pages/unauthorized';
import SalesHistory from './pages/salesHistory';
import ItemTransactionDetails from './pages/salesHistory/itemDetails';
import SubsequentSales from './pages/salesHistory/subsequentSales';

// Services 
import { GetMe } from "./services/users"
import { Logout } from './services/authenticate';

const App = () => {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const [me, setMe] = useState({});

  useEffect(() => {
    GetMe().then(user => {
      setMe(user)
    }).catch((err) => {
    })
  }, [navigate])

  const handleLogout = () => {
    Logout().then(() => {
      navigate('/')
      window.location.reload();
    })
  }

  return (
    <Fragment>
      <Routes>
        <Route path="/" exact element={<AuthPage />} />
        <Route path="/auth" exact element={<AuthPage />} />
        <Route path="/unauthorized" exact element={<Unauthorized />} />
        <Route path="/admin-submission/:token" element={<Form />} />
        <Route path="/forget-password/:token" element={<ResetPassword />} />
      </Routes>
      {me.is_dev === 1 && <><ApplicationDrawer onClick={handleLogout} avatar={me.avatar_url} />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            p: 3,
            marginLeft: { xs: `0px`, md: `${drawerWidth}px` },
          }}
        >
          <Toolbar />
          <Routes>
            <Route path='/artists' exact element={<ArtistPanel />} />
            <Route path='/users' exact element={<UserPanel />} />
            <Route path='/admins' exact element={<AuthPanel />} />
            <Route path='/profile' exact element={<Profile />} />
            <Route path='/new-admin' exact element={<NewAdmin />} />
            <Route path='/pending' exact element={<PendingPanel />} />
            <Route path='/pending/application' exact element={<ArtistProfileSubmission />} />
            <Route path='/pending-transactions' exact element={<PendingTransactionPanel />} />
            <Route path='/pending-transactions-me' exact element={<MyPendingTransactionPanel />} />
            <Route path='/sales-history' exact element={<SalesHistory />} />
            <Route path='/sales-history/item-details' exact element={<ItemTransactionDetails />} />
            <Route path='/sales-history/item-details/subsequent-sales' exact element={<SubsequentSales />} />
          </Routes>
        </Box>
      </>}

    </Fragment>

  );
};

export default App;
