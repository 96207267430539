import {FetchClient} from "../fetch-client"

export const Login = async (data)=> {
    return FetchClient("/auth/login").POST({payload: JSON.stringify(data)})
}

export const LoginWithAuthCode = async (authcode)=> {
    return FetchClient("/auth/basic/login").POST({payload: JSON.stringify({"auth_code": authcode})})
}


export const Logout = async ()=> {
    return FetchClient("/auth/logout").DELETE({})
}