import React from 'react'
import { Alert } from '@mui/material'


export default function ReusableAlert(props) {
    return (
        <Alert
            sx={{ mt: 1, mb: 2, fontWeight: 500 }}
            severity={props.severity} onClose={props.onClose} >{props.children}</Alert>
    )
}
