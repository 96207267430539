import React, { useEffect, useRef, useState } from 'react'
import { Grid, Toolbar, Typography, Paper, Box, IconButton, Tooltip, AlertTitle } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

//components
import ReusableButton from '../../components/UI/Button';
import Spinner from '../../components/UI/Spinner';
import EtherscanLogo from '../../assets/etherscan-logo-circle.svg'
import Alert from '../../components/UI/Alert';

//icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//services
import { GetMe } from '../../services/users';
import { GetItemTransactionByHash } from '../../services/transactions';
import { GetSubsequentTransactionsByItemId } from '../../services/transactions';
import { GetEtherscanTransactionByTxHash } from '../../services/etherscan';

const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_TOKEN_LINK_URL
const TOKEN_ADDRESS_V1 = process.env.REACT_APP_APOLLO_TOKEN_VERSION_1_ADDRESS
const TOKEN_ADDRESS_V2 = process.env.REACT_APP_APOLLO_TOKEN_VERSION_2_ADDRESS
const TOKEN_ADDRESS_V3 = process.env.REACT_APP_APOLLO_TOKEN_VERSION_3_ADDRESS

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1850,
    },
  },
});


const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mt: 5 }}
    >
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Item Transaction Details
      </Typography>
    </Toolbar>
  );
};

const ItemTransactionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [apolloPrice, setApolloPrice] = useState('');
  const [artistPrice, setArtistPrice] = useState('');
  const [apolloRoyalty, setApolloRoyalty] = useState('');
  const [artistRoyalty, setArtistRoyalty] = useState('');
  const [gasFee, setGasFee] = useState('');
  const [isFirstSale, setIsFirstSale] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [isSubsequentSales, setIsSubsequentSales] = useState(false);
  const [tokenAddress, setTokenAddress] = useState();
  const [alertApi, setAlertApi] = useState({ showAlert: false, title: '', message: '', severity: 'success', });
  const [isApiError, setIsApiError] = useState(false);
  const databaseData = useRef([]);
  const transactionHash = location.state;

  useEffect(() => {
    GetMe().then(() => {
      GetItemTransactionByHash(transactionHash).then(response => {
        if (response !== null) {
          if (response.items[0].version === 1) {
            setTokenAddress(TOKEN_ADDRESS_V1)
          } else if (response.items[0].version === 2) {
            setTokenAddress(TOKEN_ADDRESS_V2)
          } else if (response.items[0].version === 3) {
            setTokenAddress(TOKEN_ADDRESS_V3)
          }
          gasPriceCalculation(response.items)
          setData(response.items);
          databaseData.current = response.items;
          GetEtherscanTransactionByTxHash(transactionHash).then(response => {
            if (response.status === "1") {
              royaltyCalculation(response)
            } else {
              setAlertApi({ showAlert: true, title: 'Unable to load the data', message: 'This can happen if Etherscan API is down, or due to an underlying system issue.', severity: 'error', });
              setIsApiError(true)
              setPageLoading(false)
            }
          }).catch(err => { })
          GetSubsequentTransactionsByItemId(response.items[0].item_id, 0, undefined, undefined).then(result => {
            setIsSubsequentSales(result.items !== null)
          }).catch((err) => { })
        }
      }).catch(err => {
        setData([]);
      })
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate, transactionHash]);

  const royaltyCalculation = (response) => {
    if (response.result.length === 2) {
      setIsFirstSale(true)
      let etherscanData = response.result;
      let itemWithUsers = databaseData.current[0];
      let totalValue = 0;
      let notApolloWallet = [];
      etherscanData.forEach(element => {
        totalValue += Number(element.value);
      });
      etherscanData.forEach(element => {
        itemWithUsers.ArtistWallet.forEach(wallet => {
          if (wallet.address.toLowerCase() === element.to.toLowerCase()) {
            setArtistPrice(Number((element.value * 1e-18).toFixed(5)))
            setArtistRoyalty((element.value / totalValue * 100).toFixed(2))
            notApolloWallet.push(element.to)
          }
        });
      })
      etherscanData.forEach(element => {
        if (!notApolloWallet.includes(element.to)) {
          setApolloPrice(Number((element.value * 1e-18).toFixed(5)))
          setApolloRoyalty((element.value / totalValue * 100).toFixed(2))
        }
      })
    }
    else if (response.result.length === 3) {
      let etherscanData = response.result;
      let itemWithUsers = databaseData.current[0];
      let totalValue = 0;
      let notApolloWallet = [];
      etherscanData.forEach(element => {
        totalValue += Number(element.value);
      });
      etherscanData.forEach(element => {
        itemWithUsers.ArtistWallet.forEach(wallet => {
          if (wallet.address.toLowerCase() === element.to.toLowerCase()) {
            setArtistPrice(Number((element.value * 1e-18).toFixed(5)))
            setArtistRoyalty((element.value / totalValue * 100).toFixed(2))
            notApolloWallet.push(element.to)
          }
        });
        itemWithUsers.BuyerWallet.forEach(wallet => {
          if (wallet.address.toLowerCase() === element.to.toLowerCase()) {
            notApolloWallet.push(element.to)
          }
        })
      })
      etherscanData.forEach(element => {
        if (!notApolloWallet.includes(element.to)) {
          setApolloPrice(Number((element.value * 1e-18).toFixed(5)))
          setApolloRoyalty((element.value / totalValue * 100).toFixed(2))
        }
      })
    }
    setPageLoading(false);
  }

  const gasPriceCalculation = (response) => {
    let gasFee = 0;
    let transactionData = response[0];
    if (transactionData.minting_method === 1) {
      gasFee += Number(transactionData.effective_gas_price * transactionData.gas_used)
      gasFee += Number(transactionData.type2_details.effective_gas_price * transactionData.type2_details.gas_used)
      setGasFee(Number((gasFee * 1e-18).toFixed(6)))
    } else if (transactionData.minting_method === 0) {
      gasFee += Number(transactionData.effective_gas_price * transactionData.gas_used)
      gasFee += Number(transactionData.type1_details.effective_gas_price * transactionData.type1_details.gas_used)
      gasFee += Number(transactionData.type4_details.effective_gas_price * transactionData.type4_details.gas_used)
      setGasFee(Number((gasFee * 1e-18).toFixed(6)))
    }
  }

  const handleClickSubsequentSales = (itemId) => {
    navigate('./subsequent-sales', {
      state: itemId
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {pageLoading ? <Spinner /> :
        <>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={1} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Tooltip title="Go Back">
                <IconButton
                  aria-label="back"
                  onClick={() => { navigate(-1) }}
                  sx={{ backgroundColor: '#263af7', '&.MuiButtonBase-root:hover': { backgroundColor: '#1976d2' } }}>
                  <ArrowBackIosIcon sx={{ m: 1, color: '#ffffff' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={10} lg={9} xl={8}>
              <EnhancedTableToolbar />
              <Item elevation={0} variant="outlined" sx={{ mt: 5, p: 4, borderRadius: 3 }}>
                {data.filter(item => item.type === 3).map((filteredData, index) => {
                  return (
                    <ThemeProvider theme={theme} key={index}>
                      <Box key={index}>
                        <Grid container key={index} spacing={2} sx={{ fontFamily: 'Montserrat', fontSize: 18, color: 'black' }}>
                          <Grid item xs={12} md={6} lg={4} >
                            {filteredData.file_type === 'image' ?
                              <img alt='sample' src={filteredData.image_url} width="200" />
                              : filteredData.file_type === 'video' ?
                                < video alt='sample' src={filteredData.image_url} width="200" controls />
                                : ' Image not found!'}
                          </Grid>
                          <Grid item xs={12} md={6} lg={8}>
                            <Grid container spacing={2} >
                              <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                Item Name :
                              </Grid>
                              <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                {filteredData.name === '' ? 'Not found' : filteredData.name}
                              </Grid>
                              <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                Artist :
                              </Grid>
                              <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                {filteredData.artist.first_name === '' && filteredData.artist.last_name === "" ? 'Not found' : filteredData.artist.first_name + " " + filteredData.artist.last_name}
                              </Grid>
                              <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                Item Total Price :
                              </Grid>
                              {isFirstSale ?
                                <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                  {filteredData.minting_method === 1 ? Number((filteredData.amount).toFixed(5)) + ' ETH' : filteredData.minting_method === 0 ? Number((filteredData.type_neg1_details.amount).toFixed(5)) + ' ETH' : 'Not Found'}
                                </Grid>
                                :
                                <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                  {filteredData.amount !== '' ? Number((filteredData.amount).toFixed(5)) + ' ETH' : 'Not Found'}
                                </Grid>
                              }
                              {isFirstSale && filteredData.minting_method === 0 &&
                                <>
                                  <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                    Item Real Price :
                                  </Grid>
                                  <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                    {filteredData.assume_gas_fee === '' ? 'Not found' : Number((filteredData.amount).toFixed(5))} ETH
                                  </Grid>
                                  <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                    Estimated Gas Fee :
                                  </Grid>
                                  <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                    {filteredData.assume_gas_fee === '' ? 'Not found' : Number((filteredData.assume_gas_fee).toFixed(6))} ETH
                                  </Grid>
                                  <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                    Actual Gas Fee :
                                  </Grid>
                                  <Grid item xs={6} sm={5} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                    {gasFee} ETH
                                  </Grid>
                                </>
                              }
                              <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                Minting Method :
                              </Grid>
                              <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 600 }}>
                                {filteredData.minting_method === 1 ? 'Lazy mint' : filteredData.minting_method === 0 ? 'Normal mint' : '-'}
                              </Grid>
                              <Grid item xs={6} sm={5} md={6} lg={6} xl={5} xxl={4}>
                                View on Etherscan :
                              </Grid>
                              <Grid item xs={6} md={6} lg={6} sx={{ fontWeight: 500 }}>
                                <a href={`${ETHERSCAN_URL}${tokenAddress}?a=${filteredData.token_id}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#263af7', marginRight: 5 }}> View </a>
                                <img src={EtherscanLogo} alt="etherscan" height={20}></img>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                            Royalty Breakdown: :
                          </Grid>
                          {isApiError ? (
                            <Grid item md={12}>
                              <Alert severity={alertApi.severity}>
                                <AlertTitle>
                                  <strong>{alertApi.title}</strong>
                                </AlertTitle>
                                {alertApi.message}
                              </Alert>
                            </Grid>
                          )
                            :
                            <Grid item xs={12} md={12} lg={12} >
                              <Item sx={{ mt: 2 }}>
                                <Grid container spacing={2} sx={{ fontFamily: 'Montserrat', fontSize: 17, fontWeight: 600, color: 'black' }}>
                                  <Grid item xs={3}>Apollo</Grid>
                                  <Grid item xs={3}>{apolloRoyalty} %</Grid>
                                  <Grid item xs={3}>{apolloPrice} ETH</Grid>
                                </Grid>
                              </Item>
                              <Item sx={{ mt: 2 }}>
                                <Grid container spacing={2} sx={{ fontFamily: 'Montserrat', fontSize: 17, fontWeight: 600, color: 'black' }}>
                                  <Grid item xs={3}>Artist</Grid>
                                  <Grid item xs={3}>{artistRoyalty} %</Grid>
                                  <Grid item xs={3}>{artistPrice} ETH</Grid>
                                </Grid>
                              </Item>
                            </Grid>
                          }
                          {isFirstSale && isSubsequentSales &&
                            <Grid item xs={6} md={6} lg={4}>
                              <ReusableButton sx={{ backgroundColor: '#263af7' }} onClick={() => { handleClickSubsequentSales(filteredData.item_id) }}>Subsequent Sales</ReusableButton>
                            </Grid>
                          }
                        </Grid>
                      </Box>
                    </ThemeProvider>
                  );
                }
                )}
              </Item>
            </Grid>
          </Grid>
        </>
      }
    </Box>
  );
}

export default ItemTransactionDetails;