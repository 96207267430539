import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  ListItemText,
  ListItem,
  List,
  ListItemButton,
  ListSubheader,
  Box
  //Toolbar,
  //Typography,
} from '@mui/material';

import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';

//import logos and icons
import ApolloLogo from '../../assets/apollo-logo-tm-white.png'
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


//services
import { GetMe } from '../../services/users';

export const DrawerWidgets = () => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isApolloInternal, setIsApolloInternal] = useState(false);
  //const [path, setPath] = useState('');

  useEffect(() => {
    GetMe().then((response) => {
      setIsApolloInternal(response.is_internal !== undefined && response.is_internal)
      //let path = window.location.pathname;
      //setPath(path)
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <div style={{ backgroundColor: "black", color: 'white', fontFamily: 'Montserrat', height: '100%' }} >
      <Box sx={{ ml: 4, mt: 3, mb: 1 }}>
        <div> <img src={ApolloLogo} alt='Apollo-Logo' style={{ width: 144 }} /></div>
      </Box>
      <Divider style={{ backgroundColor: "white" }} />
      <nav>
        <Box style={{ fontWeight: 'bold', fontFamily: 'Montserrat', marginLeft: 15, marginRight: 20 }}>
          <List subheader={<ListSubheader sx={{ backgroundColor: "black", color: 'white', fontSize: 14, fontWeight: 'bold', fontFamily: 'Montserrat', mt: 2, ml: 2 }}>Management</ListSubheader>} >
            <Link to='/artists' style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                //sx={path === '/artists' ? { bgcolor: '#55585c', borderRadius: 5, maxWidth: 230, '&:hover': { backgroundColor: '#81868c' }, } : { bgcolor: 'black' }}
                >
                  <ListItemIcon>
                    {/* <Person /> */}
                    <PersonIcon sx={{ color: 'white', ml: 3 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary='Artists' style={{ fontSize: 18 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/users' style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                //sx={path === '/users' ? { bgcolor: '#55585c', borderRadius: 5, maxWidth: 230, '&:hover': { backgroundColor: '#81868c' }, } : { bgcolor: 'black' }}
                >
                  <ListItemIcon>
                    <GroupIcon sx={{ color: 'white', ml: 3 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary='Users' style={{ fontSize: 18 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/admins' style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                //sx={path === '/admins' || path === '/new-admin' ? { bgcolor: '#55585c', borderRadius: 5, maxWidth: 230, '&:hover': { backgroundColor: '#81868c' }, } : { bgcolor: 'black' }}
                >
                  <ListItemIcon>
                    <VerifiedUserIcon sx={{ color: 'white', ml: 3 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary='Admins' style={{ fontSize: 18 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <ListSubheader sx={{ backgroundColor: "black", color: 'white', fontSize: 14, fontWeight: 'bold', fontFamily: 'Montserrat', mt: 5, ml: 2 }}>Artist Approval</ListSubheader>
            <Link to='/pending' style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                >
                  <ListItemIcon>
                    <GroupIcon sx={{ color: 'white', ml: 3 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary='Pending' style={{ fontSize: 18 }} />
                </ListItemButton>
              </ListItem>
            </Link> */}
            <ListSubheader sx={{ backgroundColor: "black", color: 'white', fontSize: 14, fontWeight: 'bold', fontFamily: 'Montserrat', mt: 5, ml: 2 }}> Pending Transactions</ListSubheader>
            <Link to='/pending-transactions' style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 4}
                  onClick={(event) => handleListItemClick(event, 4)}
                //sx={path === '/pending-transactions' ? { bgcolor: '#55585c', borderRadius: 5, maxWidth: 230, '&:hover': { backgroundColor: '#81868c' }, } : { bgcolor: 'black' }}
                >
                  <ListItemIcon>
                    <ReceiptLongIcon sx={{ color: 'white', ml: 3 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary='All' style={{ fontSize: 18 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            {isApolloInternal &&
              <Link to='/pending-transactions-me' style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5)}
                  //sx={path === '/pending-transactions-me' ? { bgcolor: '#55585c', borderRadius: 5, maxWidth: 230, '&:hover': { backgroundColor: '#81868c' }, } : { bgcolor: 'black' }}
                  >
                    <ListItemIcon>
                      <ReceiptLongIcon sx={{ color: 'white', ml: 3 }} />
                    </ListItemIcon>
                    <ListItemText disableTypography primary='My' style={{ fontSize: 18 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
            }
            <ListSubheader sx={{ backgroundColor: "black", color: 'white', fontSize: 14, fontWeight: 'bold', fontFamily: 'Montserrat', mt: 5, ml: 2 }}> Sales Details</ListSubheader>
            <Link to='/sales-history' style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                //sx={path === '/sales-history' || path === '/sales-history/item-details' ? { bgcolor: '#55585c', borderRadius: 5, maxWidth: 230, '&:hover': { backgroundColor: '#81868c' }, } : { bgcolor: 'black' }}
                >
                  <ListItemIcon>
                    <AttachMoneyIcon sx={{ color: 'white', ml: 3 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary='History' style={{ fontSize: 18 }} />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
      </nav>
    </div>
  );
};


