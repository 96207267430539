import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

export default function ReusableSelect(props) {
    return (
        <FormControl size="small" fullWidth sx={{
            '& label.Mui-focused': {
                color: '#000000',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#000000',
                },
                '&:hover fieldset': {
                    borderColor: '#000000',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#000000',
                },
            }
        }}>
            <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size='small'
                value={props.value}
                label={props.title}
                onChange={props.onChange}
                sx={props.sx}
            >
                {props.menus.map((item) => (
                    <MenuItem key={item.key} value={item.value}>{item.name}</MenuItem>
                ))}

            </Select>
        </FormControl>
    )
}
