
import React from 'react'
import { Box, CircularProgress } from '@mui/material'


export default function Spinner() {
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '90vh',
    }}
  >
    <CircularProgress  sx={{color:'#000000'}}/>
  </Box>
  )
}
