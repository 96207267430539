import React from 'react';
import { Button } from '@mui/material';

export default function ReusableButton(props) {
  const { sx } = props;
  return (
    <Button
      disableElevation
      sx={
        {
          fontFamily: 'Montserrat, sans-serif',
          boxShadow: 'none',
          fontWeight: 700,
          fontSize: '14px',
          textTransform: 'none',
          ...sx,
        }
      }
      variant={props.variant || 'contained'}
      size={props.size || 'small'}
      type={props.type || 'button'}
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      className={props.className}
      component={props.component}
    >
      {props.children}
    </Button>
  );
}