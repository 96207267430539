import React, { Fragment, useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    CardActions,
    CardContent,
    Grid,
    Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './styles/auth.module.css';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableTextField from '../../components/UI/TextField';
import Alert from '../../components/UI/Alert';
import Spinner from '../../components/UI/Spinner'

//services
import { GetInvitationResultByToken, GetMe, SubmitAdminData } from '../../services/users';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export default function Form() {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ showAlert: false, message: '', severity: 'success', });
    const [submitData, setSubmitData] = useState({first_name: '', last_name: '', token: params.token ,password: '' });
    const [validData, setValidData] = useState({});
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState(false);

    useEffect(() => {
        GetMe().then(() => {
            navigate(`/artists`);
        }).catch((err) => {
            navigate(`/admin-submission/${params.token}`);
            GetInvitationResultByToken(params.token).then((response) => {
                setLoading(false)
                setValidData(response)
            }).catch(() => {
                navigate(`/`);
            })
          })
    }, [navigate, params.token]);

    const handleFirstName = (event) => {
        event.preventDefault();
        let firstName = event.target.value.trim();
        setSubmitData({ ...submitData, first_name: firstName });
        if (firstName === '') {
            setFirstNameError(true)
        } else {
            setFirstNameError(false)
            setAlert({ showAlert: false });
        }
    }
    const handleLastName = (event) => {
        event.preventDefault();
        let lastName = event.target.value.trim();
        setSubmitData({ ...submitData, last_name: lastName });
        if (lastName === '') {
            setLastNameError(true)
        } else {
            setLastNameError(false)
            setAlert({ showAlert: false });
        }
    };
    const handlePassword = (event) => {
        event.preventDefault();
        let password = event.target.value.trim();
        setSubmitData({ ...submitData, password: password });
        if (password === '') {
            setPasswordError(true)
        } else if(password.length < 8){
            setPasswordValidate(true)
        }else {
            setPasswordError(false)
            setPasswordValidate(false)
            setAlert({ showAlert: false });
        }
    };
    const handleSubmitData = async () => {
        if (submitData.first_name === '') {
            setAlert({ showAlert: true, message: "First name can't be empty", severity: 'warning', });
        } else if (submitData.last_name === '') {
            setAlert({ showAlert: true, message: "Last name can't be empty.", severity: 'warning', });
        } else if (submitData.password === '') {
            setAlert({ showAlert: true, message: "Password can't be empty.", severity: 'warning', });
        }else if (submitData.password.length < 8) {
            setAlert({ showAlert: true, message: "Password must be at least 8 characters.", severity: 'warning', });
        }else if (submitData.token === '') {
            setAlert({ showAlert: true, message: "Invalid token.", severity: 'warning', });
        } else {
            SubmitAdminData(submitData).then(() => {
                setAlert({ showAlert: true, message: "Successfully submitted.", severity: 'success', });
                setTimeout(() => {
                    window.location.replace(`${ADMIN_URL}`);
                }, 1300);
            }).catch((err) => {
                setAlert({ showAlert: true, message: "Invalid invitation.", severity: 'error', });
            })
        }
    }

    return (loading ? <Spinner /> :
        <Fragment>
            <Grid container className={styles.card}>
                <Item elevation={0} variant="outlined">
                    <Box sx={{ pt: 1, pb: 5, pl: 1, color: '#000000', fontSize: '2rem', fontWeight: 'bold', }} >
                        {'Please submit your following details'}
                    </Box>
                    <Grid container justify='center' spacing={3}>
                        <Grid item md={12}>
                            <CardContent>
                                <Grid item container spacing={1} justify='center'>
                                    <Grid item xs={12} sm={12} md={6} xl={4}>
                                        <ReusableTextField
                                            autoComplete='off'
                                            label='First Name'
                                            name='first_name'
                                            onChange={handleFirstName}
                                            error={firstNameError === true}
                                            helperText={firstNameError === true && 'First name is required!'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} xl={4}>
                                        <ReusableTextField
                                            autoComplete='off'
                                            label='Last Name'
                                            name='last_name'
                                            error={lastNameError === true}
                                            helperText={lastNameError === true && 'Last name is required!'}
                                            onChange={handleLastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} xl={4}>
                                        <ReusableTextField
                                            autoComplete='off'
                                            label='Email'
                                            name='email'
                                            value={validData.email || ''}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} xl={4}>
                                        <ReusableTextField
                                            autoComplete='new-password'
                                            label='Password'
                                            name='password'
                                            type='password'
                                            error={passwordError === true || passwordValidate === true}
                                            helperText={passwordError === true ? 'Password is required!' : passwordValidate === true && 'Must be at least 8 characters'}
                                            onChange={handlePassword}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <ReusableButton sx={{ backgroundColor: '#263AF7', '&.MuiButtonBase-root:hover': { backgroundColor: '#263AF7', }, marginLeft: '8px' }}
                                    type='Submit' disabled={submitData.first_name === '' || submitData.last_name === '' || submitData.password === ''} onClick={handleSubmitData}>
                                    Submit
                                </ReusableButton>
                            </CardActions>
                        </Grid>
                    </Grid>
                    <div className={styles.box}>
                        <p className={styles.boxDetails}>COPYRIGHT © THE APOLLO
                            ALL RIGHTS RESERVED</p>
                    </div>
                    {alert.showAlert && (
                    <Grid item md={12}>
                        <Alert
                            severity={alert.severity}
                            onClose={() =>
                                setAlert({
                                    ...alert,
                                    showAlert: false,
                                })
                            }
                        >
                            {alert.message}
                        </Alert>
                    </Grid>
                )}
                </Item>
            </Grid>
        </Fragment>
    );
};

