import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

//components
import ReusableSelect from '../../components/UI/Select';
import ReusableButton from '../../components/UI/Button';
import Spinner from '../../components/UI/Spinner';
import EtherscanLogo from '../../assets/etherscan-logo-circle.svg'

//icons
import PreviewIcon from '@mui/icons-material/Preview';

//services
import { GetMe } from '../../services/users';
import { GetSalesHistory } from '../../services/transactions';
import { GenerateFirstSalesReportWithWebSocket } from '../../services/websocket';

const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_TRANSACTION_LINK_URL

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'index',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'name',
    label: 'ITEM NAME',
  },
  {
    id: 'image_url',
    label: 'ARTWORK',
    disabled: true,
  },
  {
    id: 'edition_number',
    label: 'EDITION',
    disabled: true,
  },
  {
    id: 'creator.first_name',
    label: 'ARTIST ADMIN',
    disabled: true,
  },
  {
    id: 'user.first_name',
    label: 'ARTIST',
    disabled: true,
  },
  {
    id: 'buyer.first_name',
    label: 'BUYER',
    disabled: true,
  },
  {
    id: 'amount',
    label: 'PRICE (ETH)',
  },
  {
    id: 'created_at',
    label: 'LISTED DATE',
  },
  {
    id: 'last_updated_at',
    label: 'SOLD DATE',
  },
  {
    id: 'etherscan_link',
    label: 'VIEW ON ETHERSCAN',
    disabled: true,
  },
  {
    id: 'more',
    label: 'MORE DETAILS',
    disabled: true,
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 700, fontSize: 16 }}
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mt: 5 }}>
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Sales History
      </Typography>
    </Toolbar>
  );
};

const UnixToDate = (props) => {
  var a = new Date(props.timeStamp);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

const SalesHistory = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [filterDate, setFilterDate] = useState(0);
  const [selected, setSelected] = useState(4)
  const [pageLoading, setPageLoading] = useState(true);
  const [startingDate, setStartingDate] = useState(0)
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    GetMe().then(() => {
      GetSalesHistory(filterDate, undefined, undefined, undefined).then(response => {
        addIndexToData(response);
      }).catch((err) => {
        setData([]);
        setPageLoading(false);
      })
      reportNameDateRangeCreate(startingDate)
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate, filterDate, startingDate]);

  const addIndexToData = (response) => {
    if (response.items === null) {
      setData([]);
      setPageLoading(false);
    } else {
      let i = response.items.length;
      let userData = [];
      response.items.forEach(element => {
        element = { ...element, 'index': i };
        userData.push(element);
        i--;
      });
      setData(userData);
      setPageLoading(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //date filter
  let menus = [
    { key: 1, value: 1, name: 'Last 7 Days' },
    { key: 2, value: 2, name: 'Last Month' },
    { key: 3, value: 3, name: 'Last 3 Months' },
    { key: 4, value: 4, name: 'All' },
  ]
  const handleFilterByDate = (event) => {
    setPageLoading(true)
    let value = event.target.value;
    let number = Number(value);
    setSelected(number);
    let nowTime = Date.now();
    let date = '';
    switch (number) {
      case 1:
        date = nowTime - (1000 * 3600 * 24 * 7);
        break;
      case 2:
        date = nowTime - (1000 * 3600 * 24 * 30);
        break;
      case 3:
        date = nowTime - (1000 * 3600 * 24 * 30 * 3);
        break;
      case 4:
        date = 0;
        break;
      default:
        date = 0;
    }
    reportNameDateRangeCreate(date)
    setFilterDate(date)
    GetSalesHistory(date, undefined, undefined, undefined).then(response => {
      response.items === null ? setData([]) :
        setData(response.items);
      setPageLoading(false)
    }).catch((err) => {
      setData([]);
      setPageLoading(false)
    })
  }
  //report generation
  const reportNameDateRangeCreate = (startingDate) => {
    setStartingDate(startingDate)
    let dateRange
    let today = new Date();
    let startDate = new Date(startingDate);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = today.getFullYear();
    let month = months[today.getMonth()];
    let date = today.getDate();
    dateRange = date + '-' + month + '-' + year
    if (startingDate === 0) {
      setDateRange("By " + dateRange)
    } else if (startingDate > 0) {
      year = startDate.getFullYear();
      month = months[startDate.getMonth()];
      date = startDate.getDate();
      dateRange = date + '-' + month + '-' + year + ' - ' + dateRange
      setDateRange(dateRange)
    }
  }

  const onClickGenerate = () => {
    setPageLoading(true)
    const webSocketMessageListener = (message) => {
      if (message.data !== "") {
        const messageArray = message.data.split("$-!#$-!#")
        if (messageArray[0] === "first_sales_report_success") {
          setPageLoading(false)
          window.open(messageArray[1])
        }
      }
    }
    let offset = -(new Date().getTimezoneOffset());
    GenerateFirstSalesReportWithWebSocket(filterDate, "report", offset, dateRange, webSocketMessageListener)
  }
  const handleClickViewItemDetails = (hash) => {
    navigate('./item-details', {
      state: hash
    });
  }
  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} md={11}>
        {pageLoading ? <Spinner /> :
          <>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <EnhancedTableToolbar />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box sx={{ display: 'flex', flexDirection: { sm: 'row-reverse' }, mt: 5, mr: { md: 4, lg: 10 } }} >
                  <Item sx={{ boxShadow: 0 }}>
                    <ReusableButton sx={{ width: 175, height: '100%', backgroundColor: '#263af7' }} onClick={onClickGenerate} disabled={data && data.length === 0}>Generate Report</ReusableButton>
                  </Item>
                  <Item sx={{ boxShadow: 0 }}>
                    <ReusableSelect title={'Date'} value={selected} onChange={handleFilterByDate} menus={menus} sx={{ width: 150, mr: 3 }} />
                  </Item>
                </Box>
              </Grid>
            </Grid>
            <Item elevation={0} variant="outlined" sx={{ mr: { md: 4, lg: 10 }, mt: 5, borderRadius: 3 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750, mt: 5 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data !== null ? data.length : 0}
                  />
                  <TableBody>
                    {data &&
                      stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow tabIndex={-1} key={index} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                              <TableCell id={labelId} align='left'>
                                {row.index}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: 200 }}>{row.name !== '' ? (
                                <div style={{ fontWeight: 600, fontSize: 16 }}>{row.name}</div>
                              ) : (
                                <div style={{ marginLeft: '35px', fontWeight: 600, fontSize: 16 }}> -</div>
                              )}
                              </TableCell>
                              <TableCell align='left'>
                                {row.file_type === 'image' ?
                                  <img alt='sample' src={row.image_url} width="50" height="50" />
                                  : row.file_type === 'video' ?
                                    < video alt='sample' src={row.image_url} width="50" height="50" />
                                    : ' -'}
                              </TableCell>
                              <TableCell align='left'>
                                <div style={{ fontWeight: 500, fontSize: 16 }}>{row.edition_number + "/" + row.total_editions}</div>
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: 200 }}>
                                {row.creator.id === row.user.id ?
                                  ' -'
                                  :
                                  <div style={{ fontWeight: 600, fontSize: 16 }}>{row.creator.first_name + " " + row.creator.last_name}</div>
                                }
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: 200 }}>
                                <div style={{ fontWeight: 600, fontSize: 16 }}>{row.user.first_name + " " + row.user.last_name}</div>
                              </TableCell>
                              {row.minting_method === 1 ?
                                <>
                                  <TableCell align='left' sx={{ minWidth: 200 }}>
                                    <div style={{ fontWeight: 600, fontSize: 16 }}>{row.lazy_buyer.first_name + " " + row.lazy_buyer.last_name}</div>
                                  </TableCell>
                                  <TableCell align='left'>
                                    <div style={{ fontWeight: 500, fontSize: 16 }}>{Number((row.amount).toFixed(4))}</div>
                                  </TableCell>
                                </>
                                : row.minting_method === 0 ?
                                  <>
                                    <TableCell align='left' sx={{ minWidth: 200 }}>
                                      <div style={{ fontWeight: 600, fontSize: 16 }}>{row.normal_buyer.first_name + " " + row.normal_buyer.last_name}</div>
                                    </TableCell>
                                    <TableCell align='left'>
                                      <div style={{ fontWeight: 500, fontSize: 16 }}>{Number((row.type_neg1_details.amount).toFixed(4))}</div>
                                    </TableCell>
                                  </>
                                  : <><TableCell align='left'>Not Found</TableCell> <TableCell align='left'>Not Found</TableCell></>}
                              <TableCell align='left' sx={{ fontWeight: 500, fontSize: 16, minWidth: 200 }}>
                                <UnixToDate timeStamp={row.created_at} />
                              </TableCell>
                              <TableCell align='left' sx={{ fontWeight: 500, fontSize: 16, minWidth: 200 }}>
                                <UnixToDate timeStamp={row.tnx_created_at} />
                              </TableCell>
                              <TableCell align='left' sx={{ fontWeight: 500, fontSize: 16, minWidth: 200 }} >
                                {row.minting_method === 1 ?
                                  <>
                                    <img src={EtherscanLogo} alt="etherscan logo" height={20}></img>
                                    <a href={`${ETHERSCAN_URL}${row.type2_details.hash}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#263af7' }}> Minted</a> <br />
                                    <img src={EtherscanLogo} alt="etherscan logo" height={20}></img>
                                    <a href={`${ETHERSCAN_URL}${row.hash}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#263af7' }}> Sold</a>
                                  </>
                                  : row.minting_method === 0 ?
                                    <>
                                      <img src={EtherscanLogo} alt="etherscan logo" height={20}></img>
                                      <a href={`${ETHERSCAN_URL}${row.type1_details.hash}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#263af7' }}> Minted</a> <br />
                                      <img src={EtherscanLogo} alt="etherscan logo" height={20}></img>
                                      <a href={`${ETHERSCAN_URL}${row.type4_details.hash}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#263af7' }}> Token Approval</a> <br />
                                      <img src={EtherscanLogo} alt="etherscan logo" height={20}></img>
                                      <a href={`${ETHERSCAN_URL}${row.hash}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#263af7' }}> Sold</a>
                                    </>
                                    : 'Not Found'}
                              </TableCell>
                              <TableCell>
                                <ReusableButton onClick={() => { handleClickViewItemDetails(row.hash) }} sx={{ backgroundColor: '#263af7' }} endIcon={<PreviewIcon />}>
                                  View
                                </ReusableButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
                {data && data.length === 0 &&
                  <Box sx={{ justifyContent: 'center', mt: 3 }}>
                    <h2 style={{ textAlign: 'center' }}>No first sale transactions during selected time period </h2>
                  </Box>
                }
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={data !== null ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Item>
          </>
        }
      </Grid>
    </Grid>
  );
};

export default SalesHistory;