import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CardActions,
  CardContent,
  Grid,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableTextField from '../../components/UI/TextField';
import Alert from '../../components/UI/Alert';
import Spinner from '../../components/UI/Spinner';

//services
import { GetMe, SendAdminApprovalToEmail } from '../../services/users';

let regexEmail = /\S+@\S+\.\S+/

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function CreateAdmin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, message: '', severity: 'success', });

  useEffect(() => {
    GetMe().then((myData) => {
      myData.admin_role === 100 && navigate('/');
      setLoading(false)
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const handleEmail = (event) => {
    event.preventDefault();
    let email = event.target.value.trim();
    setEmail(email);
    if (email === '') {
      setEmailError(true)
    } else {
      setEmailError(false)
      setAlert({ showAlert: false });
    }
  };

  const handleSendEmail = async () => {
    if (email === '') {
      setAlert({ showAlert: true, message: "Email can't be empty", severity: 'warning', });
    } else if (regexEmail.test(email) === false) {
      setAlert({ showAlert: true, message: "Please enter a valid email.", severity: 'warning', });
    } else {
      SendAdminApprovalToEmail(email).then((response) => {
        setAlert({ showAlert: true, message: response.message, severity: 'success', });
        setEmail('');
      }).catch((err) => {
        setAlert({ showAlert: true, message: err.responseText.message, severity: 'error', });
      })
    }
  }

  return (loading ? <Spinner /> :
    <Fragment>
      <Grid container spacing={2} style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Item elevation={0} variant="outlined">
            <div style={{ width: '100%' }}>
              <Box
                sx={{ pt: 1, pb: 5, pl: 1, color: '#000000', fontSize: '2rem', fontWeight: 'bold', }}
              >
                {'Create an Admin'}
              </Box>
            </div>
            <Grid container justify='center' spacing={1}>
              <Grid item md={12}>
                <CardContent>
                  <Grid item container spacing={1} justify='center'>
                    <Grid item xs={12} sm={12} md={6} xl={4}>
                      <ReusableTextField
                        autoComplete='off'
                        label='Email'
                        name='email'
                        type='email'
                        error={emailError === true}
                        helperText={emailError === true && 'Email is required!'}
                        onChange={handleEmail}
                        value={email}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <ReusableButton sx={
                    {
                      border: '1px solid #263af7',
                      color: '#263af7',
                      background: 'none',
                      '&.MuiButtonBase-root:hover': {
                        background: 'none',
                        border: '1px solid #263af7',
                      },
                      marginLeft: '8px'
                    }
                  } onClick={() => navigate(-1)}>
                    Cancel
                  </ReusableButton>
                  <ReusableButton sx={
                    {
                      backgroundColor: '#263AF7',
                      '&.MuiButtonBase-root:hover': {
                        backgroundColor: '#263AF7',
                      },
                    }
                  } type='Submit' disabled={email === ''} onClick={handleSendEmail}>
                    Create
                  </ReusableButton>
                </CardActions>
              </Grid>
            </Grid>
          </Item>
          {alert.showAlert && (
            <Grid item md={12}>
              <Alert
                severity={alert.severity}
                onClose={() =>
                  setAlert({
                    ...alert,
                    showAlert: false,
                  })
                }
              >
                {alert.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

