import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

//components
import Spinner from '../../components/UI/Spinner';
import ReusableSelect from '../../components/UI/Select';
import ReusableButton from '../../components/UI/Button';

//icons
import PreviewIcon from '@mui/icons-material/Preview';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//services
import { GetMe } from '../../services/users';
import { GetSubsequentTransactionsByItemId } from '../../services/transactions';
import { GetItemWithArtistByItemId } from '../../services/items';
import { GenerateSubsequentSalesReportWithWebSocket } from '../../services/websocket';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'index',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'buyer.first_name',
    label: 'BUYER',
    disabled: true,
  },
  {
    id: 'seller.first_name',
    label: 'SELLER',
    disabled: true,
  },
  {
    id: 'amount',
    label: 'PRICE (ETH)',
  },
  {
    id: 'type2_details.second_created_at',
    label: 'LISTED DATE',
  },
  {
    id: 'tnx_created_at',
    label: 'SOLD DATE',
  },
  {
    id: 'more',
    label: 'MORE DETAILS',
    disabled: true,
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 700, fontSize: 16 }}
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mt: 5 }}>
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Subsequent Sales
      </Typography>
    </Toolbar>
  );
};

const UnixToDate = (props) => {
  var a = new Date(props.timeStamp);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

const SubsequentSales = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [artist, setArtist] = useState('');
  const [filterDate, setFilterDate] = useState(0);
  const [selected, setSelected] = useState(4)
  const [pageLoading, setPageLoading] = useState(true);
  const [startingDate, setStartingDate] = useState(0)
  const [dateRange, setDateRange] = useState();
  const itemId = location.state;


  useEffect(() => {
    GetMe().then(() => {
      GetItemWithArtistByItemId(itemId).then(response => {
        if (response !== null) {
          setItemData(response.items)
          setArtist(response.items.user.first_name + " " + response.items.user.last_name)
        }
      }).catch((err) => {
        navigate(`/`);
      })
      GetSubsequentTransactionsByItemId(itemId, filterDate, undefined, undefined, undefined).then(response => {
        addIndexToData(response);
      }).catch((err) => {
        setData([]);
        setPageLoading(false);
      })
    }).catch((err) => {
      navigate(`/`);
    })
    reportNameDateRangeCreate(startingDate)
  }, [navigate, itemId, filterDate, startingDate]);

  const addIndexToData = (response) => {
    if (response.items === null) {
      setData([]);
      setPageLoading(false);
    } else {
      let i = response.items.length;
      let userData = [];
      response.items.forEach(element => {
        element = { ...element, 'index': i };
        userData.push(element);
        i--;
      });
      setData(userData);
      setPageLoading(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickViewItemDetails = (hash) => {
    navigate('/sales-history/item-details', {
      state: hash
    });
  }
  //date filter
  let menus = [
    { key: 1, value: 1, name: 'Last 7 Days' },
    { key: 2, value: 2, name: 'Last Month' },
    { key: 3, value: 3, name: 'Last 3 Months' },
    { key: 4, value: 4, name: 'All' },
  ]
  const handleFilterByDate = (event) => {
    let value = event.target.value;
    let number = Number(value);
    setSelected(number);
    let nowTime = Date.now();
    let date = '';
    switch (number) {
      case 1:
        date = nowTime - (1000 * 3600 * 24 * 7);
        break;
      case 2:
        date = nowTime - (1000 * 3600 * 24 * 30);
        break;
      case 3:
        date = nowTime - (1000 * 3600 * 24 * 30 * 3);
        break;
      case 4:
        date = 0;
        break;
      default:
        date = 0;
    }
    reportNameDateRangeCreate(date)
    setFilterDate(date)
    GetSubsequentTransactionsByItemId(itemId, date, undefined, undefined, undefined).then(response => {
      response.items === null ? setData([]) :
        setData(response.items);
    }).catch((err) => {
      setData([]);
    })
  }
  //report generation
  const reportNameDateRangeCreate = (startingDate) => {
    setStartingDate(startingDate)
    let dateRange
    let today = new Date();
    let startDate = new Date(startingDate);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = today.getFullYear();
    let month = months[today.getMonth()];
    let date = today.getDate();
    dateRange = date + '-' + month + '-' + year
    if (startingDate === 0) {
      setDateRange("By " + dateRange)
    } else if (startingDate > 0) {
      year = startDate.getFullYear();
      month = months[startDate.getMonth()];
      date = startDate.getDate();
      dateRange = date + '-' + month + '-' + year + ' - ' + dateRange
      setDateRange(dateRange)
    }
  }
  const onClickGenerate = () => {
    setPageLoading(true)
    const webSocketMessageListener = (message) => {
      if (message.data !== "") {
        const messageArray = message.data.split("$-!#$-!#")
        if (messageArray[0] === "subsequent_sales_report_success") {
          setPageLoading(false)
          window.open(messageArray[1])
        }
      }
    }
    let offset = -(new Date().getTimezoneOffset());
    GenerateSubsequentSalesReportWithWebSocket(itemId, filterDate, "report", offset, dateRange, webSocketMessageListener)
  }
  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} md={11}>
        {pageLoading ? <Spinner /> :
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={1} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Tooltip title="Go Back">
                  <IconButton
                    aria-label="back"
                    onClick={() => { navigate(-1) }}
                    sx={{ backgroundColor: '#263af7', '&.MuiButtonBase-root:hover': { backgroundColor: '#1976d2' } }}>
                    <ArrowBackIosIcon sx={{ m: 1, color: '#ffffff' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={11} lg={11}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <EnhancedTableToolbar />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <Box sx={{ display: 'flex', flexDirection: { lg: 'row-reverse' }, mt: 5, mr: { md: 4, lg: 6, xl: 10 } }} >
                      <Item sx={{ boxShadow: 0 }}>
                        <ReusableButton sx={{ width: 175, height: '100%', backgroundColor: '#263af7' }} onClick={onClickGenerate} disabled={data && data.length === 0}>Generate Report</ReusableButton>
                      </Item>
                      <Item sx={{ boxShadow: 0 }}>
                        <ReusableSelect title={'Date'} value={selected} onChange={handleFilterByDate} menus={menus} sx={{ width: 150, mr: 3 }} />
                      </Item>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ fontFamily: 'Montserrat', fontSize: 18, color: 'black', mt: 2 }}>
                  <Grid item xs={12} md={5} lg={3} xl={2} sx={{ mr: { lg: 5, xl: 5 } }}>
                    {itemData.file_type === 'image' ?
                      <img alt='sample' src={itemData.image_url} width="200" />
                      : itemData.file_type === 'video' ?
                        < video alt='sample' src={itemData.image_url} width="200" controls />
                        : ' Image not found!'}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={2} sx={{ fontFamily: 'Montserrat', fontSize: 18, color: 'black' }}>
                      <Grid item xs={6} md={6} lg={4} xl={3}>
                        Item Name :
                      </Grid>
                      <Grid item xs={6} md={6} lg={8} sx={{ fontWeight: 600 }}>
                        {itemData.name === '' ? 'Not found' : itemData.name}
                      </Grid>
                      <Grid item xs={6} md={6} lg={4} xl={3}>
                        Edition :
                      </Grid>
                      <Grid item xs={6} md={6} lg={8} sx={{ fontWeight: 600 }}>
                        {itemData.edition_number + "/" + itemData.total_editions}
                      </Grid>
                      <Grid item xs={6} md={6} lg={4} xl={3}>
                        Artist :
                      </Grid>
                      <Grid item xs={6} md={6} lg={8} sx={{ fontWeight: 600 }}>
                        {artist}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Item elevation={0} variant="outlined" sx={{ mr: { md: 4, lg: 6, xl: 10 }, mt: 5, borderRadius: 3 }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 750, mt: 5 }} aria-labelledby='tableTitle'>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data !== null ? data.length : 0}
                      />
                      <TableBody>
                        {data &&
                          stableSort(data, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                <TableRow tabIndex={-1} key={index} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                                  <TableCell id={labelId} align='left'>
                                    {row.index}
                                  </TableCell>
                                  <TableCell align='left'>
                                    <div style={{ fontWeight: 600, fontSize: 16, minWidth: 200 }}>{row.lazy_buyer.first_name + " " + row.lazy_buyer.last_name}</div>
                                  </TableCell>
                                  <TableCell align='left'>
                                    <div style={{ fontWeight: 600, fontSize: 16, minWidth: 200 }}>{row.seller.first_name + " " + row.seller.last_name}</div>
                                  </TableCell>
                                  <TableCell align='left'>
                                    <div style={{ fontWeight: 500, fontSize: 16 }}>{row.amount}</div>
                                  </TableCell>
                                  <TableCell align='left' sx={{ fontWeight: 500, fontSize: 16, minWidth: 200 }}>
                                    <UnixToDate timeStamp={row.type2_details.created_at} />
                                  </TableCell>
                                  <TableCell align='left' sx={{ fontWeight: 500, fontSize: 16, minWidth: 200 }}>
                                    <UnixToDate timeStamp={row.tnx_created_at} />
                                  </TableCell >
                                  <TableCell>
                                    <ReusableButton onClick={() => { handleClickViewItemDetails(row.hash) }} sx={{ backgroundColor: '#263af7' }} endIcon={<PreviewIcon />}>
                                      View
                                    </ReusableButton>
                                  </TableCell>

                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                    {data && data.length === 0 &&
                      <Box sx={{ justifyContent: 'center', mt: 3 }}>
                        <h2 style={{ textAlign: 'center' }}>No subsequent transactions for this item during selected time period</h2>
                      </Box>
                    }
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={data !== null ? data.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Item>
              </Grid>
            </Grid>

          </>
        }
      </Grid>
    </Grid>
  );
};

export default SubsequentSales;






