import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Avatar,
	Grid,
	Paper,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

//components
import ReusableButton from '../../components/UI/Button';
import Modal from '../../components/UI/Modal';

//services
import { ApproveAsArtist, GetMe, GetPendingApprovalList } from '../../services/users';

const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'left',
	color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'id',
		numeric: true,
		label: 'ID',
	},
	{
		id: 'username',
		label: 'USERNAME',
	},
	{
		id: 'avatar',
		label: 'AVATAR',
	},
	{
		id: 'wallet_address',
		label: 'WALLET ADDRESS',
	},
];
function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow >
				{headCells.map((headCell) => (
					<TableCell
						sx={{ fontWeight: 700, fontSize: 16 }}
						key={headCell.id}
						align='left'
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				mt: 5
			}}
		>
			<Typography
				sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
				variant='h4'
				id='tableTitle'
				component='div'
			>
				Pending Approvals
			</Typography>
		</Toolbar>
	);
};

const PendingList = () => {
	const navigate = useNavigate();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('id');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [data, setData] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [userID, setUserId] = useState('');
	const [userName, setUserName] = useState('');

	useEffect(() => {
		GetMe().then(() => {
			GetPendingApprovalList().then(response => {
				response.artists === null ? setData([]) :
					setData(response.artists);
			}).catch((err) => {
				setData([]);
			})
		}).catch((err) => {
			navigate(`/`);
		})
	}, [navigate]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClickView = (props) => {
		const id = props;
		window.open(`${MARKETPLACE_URL}/users/${id}`);
	}

	const handleClickViewApplication = (id) => {
		navigate('./application', {
			state: id
		});
	}

	const handleOpenApproveModal = (id, username) => {
		setUserId(id);
		setUserName(username);
		setOpenModal(true);

	}
	const handleCloseModal = () => {
		setOpenModal(false);
	}
	const approveAsArtist = () => {
		ApproveAsArtist(userID).then(() => {
			GetPendingApprovalList().then(response => {
				response.artists === null ? setData([]) :
					setData(response.artists)
			}).catch((err) => {
				setData([]);
			})
			setOpenModal(false);
		})
	}

	return (
		<Grid container spacing={2} justifyContent="flex-end">
			<Grid item xs={11}>
				<EnhancedTableToolbar />
				<Item elevation={0} variant="outlined" sx={{ mr: 10, mt: 5, borderRadius: 3 }}>
					<TableContainer>
						<Table sx={{ minWidth: 750, mt: 5 }} aria-labelledby='tableTitle'>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={data.length}
							/>
							<TableBody>
								{stableSort(data, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;
										return (
											<TableRow tabIndex={-1} key={row.id} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
												<TableCell id={labelId} align='left'>
													{(index + 1) + (page * rowsPerPage)}
												</TableCell>
												<TableCell><Stack spacing={0}>
													<div style={{ fontWeight: 600, fontSize: 16 }}>{row.first_name + ' ' + row.last_name}</div>
													<div style={{ fontWeight: 600, fontSize: 16, color: '#919191' }}>{row.email}</div>
												</Stack></TableCell>
												<TableCell align='right'>
													<Avatar alt='avatar' src={row.user.avatar_url} />
												</TableCell>
												<TableCell align='left' sx={{ fontSize: 15, }}>{row.wallet_address}</TableCell>
												<TableCell align='left'>
													<ReusableButton onClick={() => handleClickView(row.user.id)}
														sx={{ backgroundColor: '#263af7' }}>
														VIEW PROFILE
													</ReusableButton>
												</TableCell>
												<TableCell align='left'>
													<ReusableButton onClick={() => { handleClickViewApplication(row.id) }} sx={{ backgroundColor: '#263af7' }}>
														VIEW APPLICATION
													</ReusableButton>
												</TableCell>
												<TableCell align='left'>
													<ReusableButton
														sx={{
															backgroundColor: '#79CC0E',
															'&.MuiButtonBase-root:hover': {
																backgroundColor: '#9dde49'
															},
														}} onClick={() => { handleOpenApproveModal(row.id, row.user.username) }}>
														APPROVE
													</ReusableButton>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component='div'
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Item>
			</Grid>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box sx={{ mb: 3 }}>
					<div style={{ fontWeight: 600, marginRight: 5 }}>Approve {userName} as a Artist?</div>
				</Box>
				<Grid container justify='center' spacing={2}>
				</Grid>
				<ReusableButton sx={
					{
						border: '1px solid #263af7',
						color: '#263af7',
						background: 'none',
						'&.MuiButtonBase-root:hover': {
							background: 'none',
							border: '1px solid #263af7',
						},
						marginRight: 2,
						marginTop: 3
					}
				} type='submit' onClick={handleCloseModal}>Cancel</ReusableButton>
				<ReusableButton sx={
					{
						backgroundColor: '#263AF7',
						'&.MuiButtonBase-root:hover': {
							backgroundColor: '#263AF7',
						},
						marginTop: 3
					}
				} type='submit' onClick={approveAsArtist}>Approve</ReusableButton>
			</Modal>
		</Grid>
	);
};

export default PendingList;