import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Cancel } from '@mui/icons-material';
import styles from './styles/auth.module.css';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableTextField from '../../components/UI/TextField';
import Alert from '../../components/UI/Alert';
import Spinner from '../../components/UI/Spinner';

//services
import { GetMe, AdminUsernameAvatarUpdate } from '../../services/users';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const Input = styled('input')({
  display: 'none',
});

const convert2Base64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (() => {
      resolve(fileReader.result);
    });
    fileReader.onerror = ((error) => {
      reject(error);
    });
  })
}

export default function Profile() {
  const navigate = useNavigate();
  const [alertUpdate, setAlertUpdate] = useState({ showAlert: false, message: '', severity: 'success', });
  const [avatarDb, setAvatarDb] = useState('');
  const [avatar, setAvatar] = useState('');
  const [imageType, setImageType] = useState('');
  const [removeAvatar, setRemoveAvatar] = useState(0);
  const [avatarFileSize, setAvatarFileSize] = useState(0);
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    GetMe().then((myData) => {
      setUsername(myData.first_name + ' ' + myData.last_name);
      setFirstName(myData.first_name);
      setLastName(myData.last_name);
      setAvatar(myData.avatar_url);
      setPageLoading(false);
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const handleChangeUsername = (event) => {
    let inputUsername = event.target.value;
    setUsername(inputUsername);
    inputUsername = inputUsername.trim()
    const [fName, ...rest] = inputUsername.split(' ');
    const lName = rest.join(' ');
    setFirstName(fName);
    setLastName(lName);
  }

  const uploadAvatar = async (e) => {
    const file = e.target.files[0];
    const size = file.size / 1024 / 1024;
    setAvatarFileSize(size)
    if (file.name === '') {
      setImageType('');
      setAvatarDb('');
    } else {
      const imageType = file.name.split('.').pop();
      setImageType(imageType);
      const base64 = await convert2Base64(file);
      setAvatar(base64);
      setAvatarDb(base64);
      setRemoveAvatar(0);
    }
  }

  const handleRemoveAvatar = () => {
    setAvatar('');
    setAvatarDb('');
    setImageType('');
    setAvatarFileSize(0);
    setRemoveAvatar(1);
  }
  const handleUsernameAvatarUpdate = () => {
    if (username === ' ' || username === '') {
      setAlertUpdate({ showAlert: true, message: "Username cannot be empty.", severity: 'warning', });
    } else if (avatarFileSize > 32) {
      setAlertUpdate({ showAlert: true, message: 'Avatar image max size exceed.', severity: 'warning', });
    } else {
      setPageLoading(true);
      AdminUsernameAvatarUpdate(firstName, lastName, username, avatarDb, imageType, removeAvatar).then((response) => {
        setDisable(true)
        setPageLoading(false);
        GetMe().then((myData) => {
          setAlertUpdate({ showAlert: true, message: response.message, severity: 'success', });
          setTimeout(() => { navigate(`/artists`) }, 1000);
        }).catch((err) => {
          navigate('/');
        })
      }
      ).catch((err) => {
        setAlertUpdate({ showAlert: true, message: "Profile update failed", severity: 'error', });
      }
      )
    }
  }

  return (
    <Fragment>
      <Grid container spacing={2} style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          {pageLoading ? <Spinner /> :
            <>
              <Item elevation={0} variant="outlined">
                <div style={{ width: '100%' }}>
                  <Box
                    sx={{
                      pt: 1,
                      pb: 5,
                      pl: 1,
                      color: '#000000',
                      fontSize: '2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {'Change Details'}
                  </Box>
                </div>
                <Grid container justify='center' spacing={1}>
                  <Grid item md={12}>
                    <CardContent>
                      <Grid item container spacing={1} justify='center'>
                        <Grid item xs={12} sm={6} md={4}>
                          <ReusableTextField
                            type='text'
                            label='Name'
                            name='name'
                            autoComplete='off'
                            onChange={handleChangeUsername}
                            value={username}
                            disabled={disable}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <h3 className={styles.avatarHeading}>Profile Avatar</h3>
                          <p className={styles.description}>Supported types: JPG JPEG PNG GIF  (500 x 500 recommended, Max Size: 32MB)</p>
                          <div className={styles.avatarFileInput} >
                            {avatar ?
                              <><div className={styles.fileInputPreview} >
                                <img src={avatar} alt="upload-thumbnail" className={styles.avatar} />
                              </div>
                                <IconButton aria-label="cancel" className={styles.cancelBtn} onClick={handleRemoveAvatar} disabled={disable}>
                                  <Cancel />
                                </IconButton> </>

                              : <div className={styles.fileInputLabel}>
                                <label htmlFor="contained-feature-file">
                                  <Input accept=".jpg, .jpeg, .png, .gif" id="contained-feature-file" type="file" onChange={uploadAvatar} disabled={disable} />
                                  <h3>Avatar</h3>
                                </label>
                              </div>}
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <ReusableButton sx={
                        {
                          backgroundColor: '#263AF7',
                          '&.MuiButtonBase-root:hover': {
                            backgroundColor: '#263AF7',
                          },
                          marginLeft: '8px'
                        }
                      } type='Submit'
                        onClick={handleUsernameAvatarUpdate}
                        disabled={disable}>
                        Update
                      </ReusableButton>
                    </CardActions>
                  </Grid>
                </Grid>
                {alertUpdate.showAlert && (
                  <Grid item md={12}>
                    <Alert
                      severity={alertUpdate.severity}
                      onClose={() =>
                        setAlertUpdate({
                          ...alertUpdate,
                          showAlert: false,
                        })
                      }
                    >
                      {alertUpdate.message}
                    </Alert>
                  </Grid>
                )}
              </Item>
            </>}
        </Grid>
      </Grid >
    </Fragment >
  );
};

