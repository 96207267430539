import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import Spinner from '../../components/UI/Spinner';

// SERVICES
import { LoginWithAuthCode } from "../../services/authenticate";
import { GetMe } from '../../services/users';

function checkAuthCodeExists(path) {
    return new URLSearchParams(path).get("authcode") === null
}

function getQueryParam(path, name) {
    return new URLSearchParams(path).get(name)
}

export default function Auth() {
    const navigate = useNavigate()
    useEffect(() => {
        GetMe().then(() => {
            navigate('/artists')
        }).catch((err) => {
            authenticationHandler()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    async function authenticationHandler() {
        if (!checkAuthCodeExists(window.location.search)) {
            try {
                await LoginWithAuthCode(getQueryParam(window.location.search, "authcode"))
                GetMe().then(() => {
                    navigate('/artists')
                }).catch((err) => {
                    navigate('/unauthorized')
                })
            } catch (error) {
                window.location.href = `${process.env.REACT_APP_ID_SERVER_BASE_URL}/auth?appid=${process.env.REACT_APP_ID_SERVER_ID}&isdisacccre=true`
            }
        } else {
            window.location.href = `${process.env.REACT_APP_ID_SERVER_BASE_URL}/auth?appid=${process.env.REACT_APP_ID_SERVER_ID}&isdisacccre=true`
        }
    }
    return (<Spinner />);
}