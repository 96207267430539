import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Grid,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

//icons
import { Edit } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableRoyaltyModal from '../../components/UI/Modal';
import ReusableStatusModal from '../../components/UI/Modal';
import ReusableRoleModal from '../../components/UI/Modal';
import ReusableTextField from '../../components/UI/TextField';
import ReusableSelect from '../../components/UI/Select';
import Spinner from '../../components/UI/Spinner';

//services
import { AddRoyalty, AddLevel, GetArtist, GetSearchArtists, ArtistIsApolloInternalUpdate, GetMe } from '../../services/users';

const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'index',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'first_name',
    label: 'NAME',
  },
  {
    id: 'avatar',
    label: 'AVATAR',
    disabled: true,
  },
  {
    id: 'wallet_address',
    label: 'WALLET ADDRESS',
    disabled: true,
  },
  {
    id: 'royalties',
    label: 'ROYALTIES',
    disabled: true,
  },
  {
    id: 'change_role',
    label: 'CHANGE ROLE',
    disabled: true,
  },
  {
    id: 'view_profile',
    label: 'VIEW PROFILE',
    disabled: true,
  },
  {
    id: 'status',
    label: 'STATUS',
    disabled: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 700, fontSize: 16 }}
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mt: 5
      }}
    >
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Artists
      </Typography>
    </Toolbar>
  );
};

const ArtistList = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRoyalty, setOpenRoyalty] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openRole, setOpenRole] = useState(false);
  const [royalty, setRoyalty] = useState(0);
  const [userId, setUserId] = useState();
  const [username, setUserName] = useState('');
  const [percentage, setPercentage] = useState('');
  const [data, setData] = useState([]);
  const [allArtists, setAllArtists] = useState([]);
  const [noMatchDisplay, setNoMatchDisplay] = useState('none');
  const [isApolloInternal, setIsApolloInternal] = useState('');
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    GetMe().then(() => {
      GetArtist().then(response => {
        addIndexToData(response);
      }).catch((err) => {
        setData([]);
      })
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const addIndexToData = (response) => {
    if (response.users === null) {
      setData([]);
      setPageLoading(false);
    } else {
      let i = 1;
      let userData = [];
      response.users.forEach(element => {
        element = { ...element, 'index': i };
        userData.push(element);
        i++;
      });
      setData(userData);
      setAllArtists(userData);
      setPageLoading(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //royalty
  const handleOpenRoyaltyModal = (id, name, percentageValue) => {
    setUserId(id)
    setUserName(name)
    setPercentage(percentageValue)
    setRoyalty(percentageValue)
    setOpenRoyalty(true);
  }
  const handleCloseRoyaltyModal = () => setOpenRoyalty(false);
  const handleRoyalties = (event) => {
    let value = event.target.value;
    let number = Number(value);
    setRoyalty(number);
  };
  const handleUpdateRoyalty = async () => {
    AddRoyalty(userId, royalty).then(() => {
      GetArtist().then(response => {
        addIndexToData(response);
      }).catch(() => {
        setData([]);
      })
      setOpenRoyalty(false)
    })
  }

  //status
  const handleOpenStatusModal = (id, level, username) => {
    setUserId(id);
    setUserName(username);
    setOpenStatus(true);
  }
  const handleCloseStatusModal = () => {
    setOpenStatus(false);
  }
  const handleStatusChange = () => {
    AddLevel(userId, 1).then(() => {
      ArtistIsApolloInternalUpdate(userId, 0).then(() => {
        GetMe().then((me) => {
          if (userId === me.id) {
            window.location.reload();
          }
        }).catch((err) => {
          navigate(`/`);
        })
        GetArtist().then(response => {
          addIndexToData(response);
        }).catch(() => {
          setData([]);
        })
      })
    })
    setOpenStatus(false);
  }

  //role
  let menus = [
    { key: 1, value: false, name: 'Artist' },
    { key: 2, value: true, name: 'Artist Admin' },
  ]
  const handleOpenChangeRoleModal = (id, username, isApolloInternal) => {
    setUserId(id);
    setUserName(username);
    setIsApolloInternal(isApolloInternal);
    setOpenRole(true);
  }
  const handleCloseRoleModal = () => {
    setOpenRole(false);
    setIsApolloInternal('');
  }
  const handleChangeRole = (event) => {
    let value = event.target.value;
    setIsApolloInternal(value);
  }
  const handleUpdateRole = () => {
    ArtistIsApolloInternalUpdate(userId, isApolloInternal).then(() => {
      GetMe().then((me) => {
        if (userId === me.id) {
          window.location.reload();
        }
      }).catch((err) => {
        navigate(`/`);
      })
      GetArtist().then(response => {
        addIndexToData(response);

      }).catch((err) => {
        setData([]);
      })
      setOpenRole(false);
      setIsApolloInternal('');
    })
  }

  const handleClickView = (props) => {
    const id = props;
    window.open(`${MARKETPLACE_URL}/users/${id}`);
  }

  //search
  // eslint-disable-next-line no-unused-vars
  const handleSearchArtists = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm === '') {
      setData(allArtists);
      setNoMatchDisplay('none');
    }
    else if (searchTerm !== '') {
      GetSearchArtists(searchTerm).then(response => {
        response === null ? setData([]) :
          setData(response);
        setNoMatchDisplay('none');
      }).catch((err) => {
        setData([]);
        setNoMatchDisplay('');
      })
    }
  }

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} md={11}>
        {pageLoading ? <Spinner /> :
          <>
            <Grid container>
              <Grid item xs={7}>
                <EnhancedTableToolbar />
              </Grid>
              {/* <Grid item xs={5}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 5, mr: 10, height: 45 }} >
              <input type='text'
                placeholder='Search usernames, emails and wallets'
                style={{ fontSize: 18, border: '1px solid ', borderColor: '#b9bec7', borderRadius: 10, width: 360, padding: 5 }}
                onChange={(event) => {
                  handleSearchArtists(event);
                }} />
            </Box>
          </Grid> */}
            </Grid>
            <Item elevation={0} variant="outlined" sx={{ mr: { md: 4, lg: 6, xl: 10 }, mt: 5, borderRadius: 3 }}>
              <TableContainer >
                <Table sx={{ minWidth: 750, mt: 5 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data !== null ? data.length : 0}
                  />
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow tabIndex={-1} key={index} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                            <TableCell id={labelId} align='left' >
                              {/* {(index + 1) + (page * rowsPerPage)} */}
                              {row.index}
                            </TableCell>
                            <TableCell><Stack spacing={0}>{row.first_name !== '' || row.last_name !== '' ? (
                              <div style={{ fontWeight: 600, fontSize: 16 }}>{row.first_name + ' ' + row.last_name}</div>
                            ) : (
                              <div style={{ fontWeight: 600, fontSize: 16 }}>-</div>
                            )}
                              <div style={{ fontWeight: 600, fontSize: 16, color: '#919191' }}>{row.Email.address}</div>
                            </Stack></TableCell>
                            <TableCell align='right'>
                              <Avatar alt='avatar' src={row.avatar_url} />
                            </TableCell>
                            <TableCell align='left' sx={{ fontSize: 15 }}>{
                              <>
                                {row.WalletList !== null ? (
                                  row.WalletList.map((wallet) => {
                                    return wallet.address + "\n"
                                  })
                                ) : ("-")
                                }
                              </>
                            }</TableCell>
                            <TableCell align='center'>
                              {row.is_internal === true ? (
                                <div style={{ fontWeight: 600, fontSize: 16 }}>-</div>
                              ) : row.is_internal === false ? (
                                <ReusableButton endIcon={<Edit />} sx={{
                                  backgroundColor: '#7c8fa1',
                                  '&.MuiButtonBase-root:hover': {
                                    backgroundColor: '#92A9BD',
                                  },
                                }} onClick={() => handleOpenRoyaltyModal(row.id, row.username, row.royalities)}>{row.royalities}%
                                </ReusableButton>
                              ) : ""}

                            </TableCell>
                            <TableCell>
                              <ReusableButton endIcon={<Edit />} sx={{ backgroundColor: '#263AF7', minWidth: '210px' }}
                                onClick={() => handleOpenChangeRoleModal(row.id, row.username, row.is_internal)}>
                                {row.is_internal === true ? 'Artist Admin' : 'Artist'}
                              </ReusableButton>
                            </TableCell>
                            <TableCell align='left'>
                              <ReusableButton onClick={() => handleClickView(row.id)} sx={{ backgroundColor: '#263af7' }} endIcon={<PreviewIcon />}>
                                View
                              </ReusableButton>
                            </TableCell>
                            <TableCell align='left'>
                              <ReusableButton endIcon={<Edit />} size='small'
                                sx={row.level === 50 ? {
                                  backgroundColor: '#79CC0E', '&.MuiButtonBase-root:hover': {
                                    backgroundColor: '#9dde49'
                                  },
                                } : { backgroundColor: '#CC0E0E' }}
                                onClick={() => handleOpenStatusModal(row.id, row.level, row.username)}>
                                {row.level === 50 ? 'Active' : 'Inactive'}
                              </ReusableButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <Box sx={{ justifyContent: 'center', mt: 3 }}>
                  <h2 style={{ display: noMatchDisplay, textAlign: 'center' }}>No matches found!</h2>
                </Box>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Item>
          </>
        }
      </Grid>
      <ReusableRoyaltyModal open={openRoyalty} onClose={handleCloseRoyaltyModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 1,
            mb: 3
          }}
        >
          <div style={{ fontWeight: 600, marginRight: 5 }}>{username}</div>
          <div style={{ color: '#DD4A48' }}>{percentage}%</div>
        </Box>
        <ReusableTextField label='Royalties' autoComplete='off'
          name='royalities'
          type='number' onChange={handleRoyalties} value={royalty}
          onWheel={(e) => e.target.blur()}
          InputProps={{ inputProps: { min: 0 } }} />
        <ReusableButton sx={
          {
            border: '1px solid #263af7',
            color: '#263af7',
            background: 'none',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              border: '1px solid #263af7',
            },
            marginRight: 2,
            marginTop: 3
          }
        } onClick={handleCloseRoyaltyModal} type='submit'>Cancel</ReusableButton>
        <ReusableButton sx={
          {
            backgroundColor: '#263AF7',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#263AF7',
            },
            marginTop: 3
          }
        } disabled={royalty === 0 || royalty === ''} onClick={handleUpdateRoyalty} type='submit'>Update</ReusableButton>
      </ReusableRoyaltyModal>

      <ReusableRoleModal open={openRole} onClose={handleCloseRoleModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 1,
            mb: 3
          }}
        >
          <div style={{ fontWeight: 600, marginRight: 5 }}>{username}</div>
        </Box>
        <ReusableSelect title={'Role'} value={isApolloInternal} onChange={handleChangeRole} menus={menus} />
        <ReusableButton sx={
          {
            border: '1px solid #263af7',
            color: '#263af7',
            background: 'none',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              border: '1px solid #263af7',
            },
            marginRight: 2,
            marginTop: 3
          }
        } onClick={handleCloseRoleModal} type='submit'>Cancel</ReusableButton>
        <ReusableButton sx={
          {
            backgroundColor: '#263AF7',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#263AF7',
            },
            marginTop: 3
          }
        } onClick={handleUpdateRole} type='submit' disabled={isApolloInternal === ''}>Update</ReusableButton>
      </ReusableRoleModal>

      <ReusableStatusModal open={openStatus} onClose={handleCloseStatusModal}>
        <Box sx={{ mb: 3, fontWeight: 600 }}>
          Are you sure you want to deactivate {username} ?
        </Box>
        <ReusableButton sx={
          {
            border: '1px solid #263af7',
            color: '#263af7',
            background: 'none',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              border: '1px solid #263af7',
            },
            marginRight: 2,
            marginTop: 3
          }
        } onClick={handleCloseStatusModal} type='submit'>Cancel</ReusableButton>

        <ReusableButton sx={
          {
            backgroundColor: '#ff0000',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#bb0000',
            },
            marginTop: 3
          }
        } onClick={handleStatusChange} type='submit'> Deactivate </ReusableButton>
      </ReusableStatusModal>
    </Grid>
  );
};

export default ArtistList;
