import React from 'react'
import { TextField } from '@mui/material'

export default function ReusableTextField(props) {
  const { sx } = props;
  return (
    <TextField
      sx={{
        '& .MuiInputBase-input': {
          borderColor: '#000000'
        },
        '& label.Mui-focused': {
          color: '#000000',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#000000'
          },
          '&:hover fieldset': {
            borderColor: '#000000'
          },
          '&.Mui-focused fieldset': {
            borderColor: '#000000'
          }
        },
        ...sx,
      }}
      label={props.label}
      variant='outlined'
      fullWidth
      name={props.name}
      size='small'
      type={props.type}
      value={props.value}
      autoComplete={props.autoComplete}
      onChange={props.onChange}
      autoFocus={props.autoFocus}
      InputProps={props.InputProps}
      disabled={props.disabled}
      error={props.error}
      helperText={props.helperText}
      onWheel={props.onWheel}
    />
  )
}
