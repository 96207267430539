import { FetchClient } from "../fetch-client"

export const GetBuyItemsTransactions = async (filterDate) => {
    return FetchClient(`/transactions?filterDate=${filterDate}`).GET({})
}

export const GetSoldArtworks = async (filterDate) => {
    return FetchClient(`/item/sold/count?filterDate=${filterDate}`).GET({})
}

export const GetListedArtworks = async (filterDate) => {
    return FetchClient(`/item/listed/count?filterDate=${filterDate}`).GET({})
}

export const GetPendingTransactions = async () => {
    return FetchClient(`/purchases/all`).GET({})
}

export const GetMyPendingTransactions = async () => {
    return FetchClient(`/purchases/me`).GET({})
}

export const GetSalesHistory = async (filterDate, report, offset, dateRange) => {
    return FetchClient(`/items/sold?filterDate=${filterDate}&${report !== undefined ? `report=${report}&` : ``}${offset !== undefined ? `offset=${offset}&` : `offset=${0}`}${dateRange !== undefined ? `dateRange=${dateRange}&` : ``}`).GET({})
}

export const GetItemTransactionByHash = async (hash) => {
    return FetchClient(`/item/transaction?hash=${hash}`).GET({})
}

export const GetSubsequentTransactionsByItemId = async (itemId, filterDate, report, offset, dateRange) => {
    return FetchClient(`/item/subtransaction-list?itemid=${itemId}&filterDate=${filterDate}&${report !== undefined ? `report=${report}&` : ``}${offset !== undefined ? `offset=${offset}&` : `offset=${0}`}${dateRange !== undefined ? `dateRange=${dateRange}&` : ``}`).GET({})
}

