const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

const websocket = (path, webSocketMessageListener) => {
  let socket = new WebSocket(`${WEBSOCKET_URL}${path}`)

  socket.onopen = () => {
    socket.send("Hi from the client")
  }

  socket.onmessage = (msg) => {
    webSocketMessageListener(msg)
  }

  socket.onerror = (error) => {
  }
}

export const GenerateFirstSalesReportWithWebSocket = (filterDate, report, offset, dateRange, webSocketMessageListener) => {
  return websocket(`/websocket/first-sales-report?filterDate=${filterDate}&${report !== undefined ? `report=${report}&` : ``}${offset !== undefined ? `offset=${offset}&` : `offset=${0}`}${dateRange !== undefined ? `dateRange=${dateRange}&` : ``}`, webSocketMessageListener)
}

export const GenerateSubsequentSalesReportWithWebSocket = (itemId, filterDate, report, offset, dateRange, webSocketMessageListener) => {
  return websocket(`/websocket/subsequent-sales-report?itemid=${itemId}&filterDate=${filterDate}&${report !== undefined ? `report=${report}&` : ``}${offset !== undefined ? `offset=${offset}&` : `offset=${0}`}${dateRange !== undefined ? `dateRange=${dateRange}&` : ``}`, webSocketMessageListener)
}