import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableMintTransferModel from '../../components/UI/Modal';
import Spinner from '../../components/UI/Spinner';

//services
import { GetMe } from '../../services/users';
import { GetMyPendingTransactions } from '../../services/transactions';

const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'index',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'item_image',
    label: 'ITEM IMAGE',
    disabled: true,
  },
  {
    id: 'buyer.buyer_name',
    label: 'BUYER NAME',
    disabled: true,
  },
  {
    id: 'artist.artist_name',
    label: 'ARTIST NAME',
    disabled: true,
  },
  {
    id: 'action',
    label: 'ACTION',
    disabled: true,
  },
  {
    id: 'created_at',
    label: 'PURCHASED DATE',
  }
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 700, fontSize: 16 }}
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mt: 5
      }}
    >
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        My Pending Transactions
      </Typography>
    </Toolbar>
  );
};

const UnixToDate = (props) => {
  var a = new Date(props.timeStamp);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

const MyPendingTransactionList = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [openMintTransferModel, setOpenMintTransferModel] = useState(false);
  const [itemId, setItemId] = useState('');
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    GetMe().then(() => {
      GetMyPendingTransactions().then(response => {
        addIndexToData(response);
      }).catch((err) => {
        setData([]);
        setPageLoading(false);
      })
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const addIndexToData = (response) => {
    if (response === null) {
      setData([])
      setPageLoading(false)
    } else {
      let i = response.length;
      let userData = [];
      response.forEach(element => {
        element = { ...element, 'index': i };
        userData.push(element);
        i--;
      });
      setData(userData);
      setPageLoading(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickMintTransfer = (props) => {
    const id = props;
    setOpenMintTransferModel(true);
    setItemId(id);
  }
  const handleCloseMintTransferModal = () => {
    setOpenMintTransferModel(false);
  }
  const handleClickContinue = () => {
    window.open(`${MARKETPLACE_URL}/item/${itemId}`);
    setOpenMintTransferModel(false);
  }

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} md={11}>
        {pageLoading ? <Spinner /> :
          <>
            <EnhancedTableToolbar />
            <Item elevation={0} variant="outlined" sx={{ mr: { md: 4, lg: 6, xl: 10 }, mt: 5, borderRadius: 3 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750, mt: 5 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow tabIndex={-1} key={row.id} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                            <TableCell id={labelId} align='left'>
                              {row.index}
                            </TableCell>
                            <TableCell>
                              {row.file_type === 'image' ?
                                <img alt='sample' src={row.image_url} width="50" height="50" />
                                : row.file_type === 'video' ?
                                  < video alt='sample' src={row.image_url} width="50" height="50" />
                                  : ' -'}
                            </TableCell>
                            <TableCell align='left'>{row.buyer.first_name !== '' || row.buyer.last_name !== '' ? (
                              <div style={{ fontWeight: 600, fontSize: 16 }}>{row.buyer.first_name + " " + row.buyer.last_name}</div>
                            ) : (
                              <div style={{ marginLeft: '35px', fontWeight: 600, fontSize: 16 }}> -</div>
                            )}
                            </TableCell>
                            <TableCell align='left'>{row.artist.first_name !== '' || row.artist.last_name !== '' ? (
                              <div style={{ fontWeight: 600, fontSize: 16 }}>{row.artist.first_name + " " + row.artist.last_name}</div>
                            ) : (
                              <div style={{ marginLeft: '35px', fontWeight: 600, fontSize: 16 }}> -</div>
                            )}
                            </TableCell>
                            <TableCell align='left' style={{ fontWeight: 600, fontSize: 16 }}>
                              <ReusableButton onClick={() => handleClickMintTransfer(row.item_id)} sx={{ backgroundColor: '#263af7' }}>
                                {'MINT & TRANSFER'}
                              </ReusableButton>
                            </TableCell>
                            <TableCell align='left' style={{ fontWeight: 600, fontSize: 16 }}>
                              <UnixToDate timeStamp={row.created_at} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Item>
          </>
        }
      </Grid>
      <ReusableMintTransferModel open={openMintTransferModel} onClose={handleCloseMintTransferModal}>
        <Box sx={{ mb: 3, fontWeight: 600 }}>
          <InfoOutlinedIcon color='primary' fontSize='small' /> Make sure to connect  your Artist Representative wallet account with the marketplace
        </Box>
        <ReusableButton sx={
          {
            border: '1px solid #263af7',
            color: '#263af7',
            background: 'none',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              border: '1px solid #263af7',
            },
            marginRight: 2,
            marginTop: 3
          }
        } onClick={handleCloseMintTransferModal} type='submit'>Cancel</ReusableButton>

        <ReusableButton sx={
          {
            backgroundColor: '#263af7',
            marginTop: 3
          }
        } onClick={handleClickContinue} type='submit'> Continue </ReusableButton>
      </ReusableMintTransferModel>
    </Grid>
  );
};

export default MyPendingTransactionList;






