const ETHERSCAN_API_URL = process.env.REACT_APP_ETHERSCAN_API_URL

class APIError extends Error {
    status=0
    statusText = "";
    responseText = ""
    constructor(status, statusText, responseText){
        super(statusText)
        this.status = status
        this.statusText = statusText
        this.responseText = responseText
    }
}

const makeReq = async (path) => {
    let response = await fetch(`${ETHERSCAN_API_URL}${path}`)
    if (response.status === 403) {
        return await makeReq(path);
    }
    if (!response.ok) {
        throw new APIError(response.status, response.statusText, await response.text());
    } 
    let contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        return response.json()
    } else {
        return response.text()
    }
}

export const FetchEtherscan = (path) => {
    return makeReq(path)
}