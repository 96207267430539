import { FetchClient } from "../fetch-client"


export const GetMe = async () => {
    return FetchClient("/users/me").GET({})
}

export const GetArtist = async () => {
    return FetchClient(`/users?level=50`).GET({})
}

export const GetUsers = async () => {
    return FetchClient(`/users?level=1`).GET({})
}

export const GetAdmins = async () => {
    return FetchClient(`/users/administrators`).GET({})
}

export const GetPendingApprovalList = async () => {
    return FetchClient(`/users/be-an-artists?status=1`).GET({})
}

export const SendAdminApprovalToEmail = async (email) => {
    return FetchClient(`/users/invite`).POST({ payload: JSON.stringify({ email }) })
}

export const GetInvitationResultByToken = async (token) => {
    return FetchClient(`/invitations/validate?token=${token}`).GET({})
}

export const GetSearchArtists = async (searchTerm) => {
    return FetchClient(`/users/search?q=${searchTerm}&level=50`).GET({})
}

export const SubmitAdminData = async (submitData) => {
    return FetchClient(`/users/invite/create`).POST({ payload: JSON.stringify(submitData) })
}

export const UpdatePassword = async (oldPassword, newPassword) => {
    return FetchClient(`/users/update-password`).PUT({ payload: JSON.stringify({ old_password: oldPassword, new_password: newPassword }) })
}

export const CreateAdmin = async (userId) => {
    return FetchClient(`/users/admin?userid=${userId}`).POST({})
}

export const AddRoyalty = async (userId, royalty) => {
    return FetchClient(`/users/royalities?userid=${userId}&royalities=${royalty}`).PUT({})
}

export const AddLevel = async (userId, level) => {
    return FetchClient(`/users/level?userid=${userId}&level=${level}`).PUT({})
}

export const ApproveAsArtist = async (userId) => {
    return FetchClient(`/users/be-an-artist/confirm?formid=${userId}`).PUT({})
}

export const AdminUsernameAvatarUpdate = async (firstName, lastName, username, avatarData, avatarType, removeAvatar) => {
    return FetchClient(`/users/update?removeavatar=${removeAvatar}`).PUT({ payload: JSON.stringify({ first_name: firstName, last_name: lastName, username: username, avatar_data: avatarData, avatar_type: avatarType }) })
}

export const AdminRoleStatusUpdate = async (adminId, adminRole, adminStatus) => {
    return FetchClient(`/users/privilege?id=${adminId}`).PUT({ payload: JSON.stringify({ is_dev: adminStatus, admin_role: adminRole }) })
}//not using

export const AdminRoleUpdate = async (adminId, adminRole) => {
    return FetchClient(`/users/admin-role?id=${adminId}`).PUT({ payload: JSON.stringify({ admin_role: adminRole }) })
}

export const AdminStatusUpdate = async (adminId, adminStatus) => {
    return FetchClient(`/users/admin-status?id=${adminId}`).PUT({ payload: JSON.stringify({ is_dev: adminStatus }) })
}

export const ArtistIsApolloInternalUpdate = async (userId, isApolloInternal) => {
    return FetchClient(`/users/apollo-internal?userid=${userId}&is_internal=${isApolloInternal}`).PUT({})
}

export const ResetPasswordStart = async (email) => {
    return FetchClient(`/users/forget-password/start`).POST({ payload: JSON.stringify({ email }) })
}

export const ResetPasswordSend = async (submitData) => {
    return FetchClient(`/users/forget-password`).POST({ payload: JSON.stringify(submitData) })
}