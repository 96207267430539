import React, { useEffect, useState } from 'react'
import { Grid, Toolbar, Typography, Paper, Box, CardActionArea, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableImageModal from '../../components/UI/ImageModal';
import ReusableSvgModal from '../../components/UI/ImageModal';

//icons
import WebIcon from '@mui/icons-material/Web';
import ArticleIcon from '@mui/icons-material/Article';

//services
import { GetMe, GetPendingApprovalList } from '../../services/users';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mt: 5
      }}
    >
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Artist Profile Submission Details
      </Typography>
    </Toolbar>
  );
};

const ViewArtworks = (props) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openSvgModal, setOpenSvgModal] = useState(false);
  const [artworkUrl, setArtworkUrl] = useState('');
  const [svgUrl, setSvgUrl] = useState('')
  const svgArray = [];
  const imageArray = [];
  const videoArray = [];
  const audioArray = [];
  const artworkUrlList = props.url
  const artworkUrlArray = artworkUrlList.split(",");

  artworkUrlArray.forEach(element => {
    const tempArtwork = element.split(".");
    const extension = tempArtwork[tempArtwork.length - 1];

    if (extension.includes("svg")) {
      svgArray.push(element);
    }
    else if (extension.includes("image")) {
      imageArray.push(element);
    } else if (extension.includes("video")) {
      videoArray.push(element);
    } else if (extension.includes("audio")) {
      audioArray.push(element);
    }
  });

  const handleOpenImageModal = (artworkUrl) => {
    setArtworkUrl(artworkUrl);
    setOpenImageModal(true);
  }
  const handleCloseImageModal = () => {
    setOpenImageModal(false)
  }

  const handleOpenSvgModal = (svgUrl) => {
    setSvgUrl(svgUrl);
    setOpenSvgModal(true);
  }
  const handleCloseSvgModal = () => {
    setOpenSvgModal(false);
  }

  return (
    <Box>
      <Box>
        <Grid container spacing={2} marginTop={4}>
          {imageArray.map(artworkUrl => {
            return (
              <Grid item key={artworkUrl} xs={3}>
                <CardActionArea sx={{ position: 'relative', }} onClick={() => { handleOpenImageModal(artworkUrl) }}>
                  <CardMedia component='img' sx={{ height: 200 }} image={artworkUrl} alt='' />
                </CardActionArea>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <br />
      <Box>
        <Grid container spacing={2} marginTop={2}>
          {svgArray.map(svgUrl => {
            return (
              <Grid item key={svgUrl} xs={3} >
                <CardActionArea sx={{ position: 'relative', }} onClick={() => { handleOpenSvgModal(svgUrl) }}>
                  <CardMedia component='iframe' sx={{ height: 200 }} image={svgUrl} alt='' onClick={() => { handleOpenSvgModal(svgUrl) }} />
                  <div style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute' }}></div>
                </CardActionArea>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <br />
      <Box>
        <Grid container spacing={2} marginTop={2}>
          {videoArray.map(videoUrl => {
            return (
              <Grid item key={videoUrl} xs={4}>
                <CardActionArea sx={{ position: 'relative', }}>
                  <CardMedia component='video' sx={{ height: 200 }} src={videoUrl} alt='' controls />
                </CardActionArea>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <br />
      <Box>
        <Grid container spacing={2} marginTop={2}>
          {audioArray.map(audioUrl => {
            return (
              <Grid item key={audioUrl} xs={4}>
                <CardActionArea sx={{ position: 'relative', }}>
                  <CardMedia component='audio' src={audioUrl} alt='' controls />
                </CardActionArea>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <ReusableImageModal open={openImageModal} onClose={handleCloseImageModal}>
        <CardMedia component='img' image={artworkUrl} alt='' />
      </ReusableImageModal>
      <ReusableSvgModal open={openSvgModal} onClose={handleCloseSvgModal}>
        <CardMedia component='iframe' image={svgUrl} alt='' />
      </ReusableSvgModal>
    </Box>
  )
}

const ArtistProfileSubmission = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const userId = location.state;

  useEffect(() => {
    GetMe().then(() => {
      GetPendingApprovalList().then(response => {
        setData(response.artists)
      })
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const handleViewWebsite = (websiteUrl) => {
    window.open(websiteUrl);
  };
  const handleViewCV = (cvUrl) => {
    window.open(cvUrl);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <EnhancedTableToolbar />
          <Item elevation={0} variant="outlined" sx={{ mt: 5, p: 4, borderRadius: 3 }}>
            {data.filter(item => item.id === userId).map((filteredData, index) => {
              return (
                <Box key={filteredData.id}>
                  <Grid container key={filteredData.id} spacing={2} sx={{ fontSize: 18, color: 'black' }}>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      NAME
                    </Grid>
                    <Grid item xs={8} md={6} lg={8}>
                      {filteredData.first_name === '' && filteredData.last_name === '' ? 'Not found' : filteredData.first_name + ' ' + filteredData.last_name}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      EMAIL
                    </Grid>
                    <Grid item xs={8} md={6} lg={8}>
                      {filteredData.email}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      PHONE
                    </Grid>
                    <Grid item xs={8} md={6} lg={8}>
                      {filteredData.phone !== '' ? filteredData.phone : 'Not found'}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      GALLERY REP.
                    </Grid>
                    <Grid item xs={8} md={6} lg={8}>
                      {filteredData.gallery_name !== '' ?
                        filteredData.gallery_name : 'Not found'}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      WEBSITE
                    </Grid>
                    <Grid item xs={8} md={6} lg={8}>
                      {filteredData.website !== '' ?
                        <ReusableButton sx={{ backgroundColor: '#463af7' }} onClick={() => {
                          handleViewWebsite(filteredData.website);
                        }}>
                          <WebIcon sx={{ mr: 1 }} />
                          View Website
                        </ReusableButton> : 'Not found'}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      BIO/STATEMENT
                    </Grid>
                    <Grid item xs={8} md={6} lg={8} sx={{ textAlign: 'justify' }}>
                      {filteredData.bio !== '' ? filteredData.bio : 'Not found'}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      CV
                    </Grid>
                    <Grid item xs={8} md={6} lg={8}>
                      {filteredData.cv_url !== '' ?
                        <ReusableButton sx={{ backgroundColor: '#463af7' }} onClick={() => {
                          handleViewCV(filteredData.cv_url);
                        }}>
                          <ArticleIcon sx={{ mr: 1 }} />
                          View CV
                        </ReusableButton> : 'Not found'}
                    </Grid>
                    <Grid item xs={4} md={4} lg={3} sx={{ fontWeight: 700 }}>
                      ARTWORKS
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    {filteredData.artwork_url !== '' ?
                      <ViewArtworks url={filteredData.artwork_url} /> : 'Not found'}
                  </Grid>
                </Box>
              );
            }
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ArtistProfileSubmission;