import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import ApolloLogo from '../../assets/apollo-market-logo.png';
import { useNavigate } from 'react-router-dom';

//components
import ReusableButton from '../../components/UI/Button'
import Spinner from '../../components/UI/Spinner';

//ICONS
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

// SERVICES
import { GetMe } from '../../services/users';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://theapollo.com/">
                The Apollo
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Unauthorized() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        GetMe().then(() => {
            navigate('/artists')
        }).catch((err) => {
            setLoading(false)
            navigate('/unauthorized');
        })
    }, [navigate]);

    const handleOnClick = () => {
        window.location.href = `${process.env.REACT_APP_ID_SERVER_BASE_URL}/auth?appid=${process.env.REACT_APP_ID_SERVER_ID}&isdisacccre=true`
    }

    return (loading ? <Spinner /> :
        <Fragment>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={ApolloLogo} height="75" alt='' style={{ marginBottom: 20 }} />
                    <Box sx={{ mb: 5, fontSize: 20, fontFamily: 'Montserrat, sans-serif', fontWeight: 500, textAlign: 'center' }}>
                        <WarningAmberRoundedIcon fontSize='large' />
                        <div>You don't have access to this website or your session has expired</div>
                    </Box>
                    <ReusableButton type="submit" fullWidth sx={{
                        backgroundColor: '#000000',
                        '&.MuiButtonBase-root:hover': {
                            backgroundColor: '#000000',
                        },
                    }}
                        onClick={handleOnClick}>
                        Back to Login
                    </ReusableButton>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </Fragment>
    );
}