import React, { Fragment, useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    CardActions,
    CardContent,
    Grid,
    Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './styles/auth.module.css';

//components
import ReusableButton from '../../components/UI/Button';
import ReusableTextField from '../../components/UI/TextField';
import Alert from '../../components/UI/Alert';
import Spinner from '../../components/UI/Spinner'

//services
import {GetMe, ResetPasswordSend } from '../../services/users';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export default function ResetPassword() {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ showAlert: false, message: '', severity: 'success', });
    const [submitData, setSubmitData] = useState({token: params.token ,password: '' });
    const [passwordError, setPasswordError] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState(false);

    useEffect(() => {
        GetMe().then(() => {
            navigate(`/artists`);
        }).catch((err) => {
            navigate(`/forget-password/${params.token}`);
            setLoading(false)
          })
    }, [navigate, params.token]);

    const handlePassword = (event) => {
        event.preventDefault();
        let password = event.target.value.trim();
        setSubmitData({ ...submitData, password: password });
        if (password === '') {
            setPasswordError(true)
        } else if(password.length < 8){
            setPasswordValidate(true)
        }else {
            setPasswordError(false)
            setPasswordValidate(false)
            setAlert({ showAlert: false });
        }
    };
    const handleSubmitData = async () => {
        if (submitData.password === '') {
            setAlert({ showAlert: true, message: "Password can't be empty.", severity: 'warning', });
        }else if (submitData.password.length < 8) {
            setAlert({ showAlert: true, message: "Password must be at least 8 characters.", severity: 'warning', });
        }else if (submitData.token === '') {
            setAlert({ showAlert: true, message: "Invalid token.", severity: 'warning', });
        } else {
            ResetPasswordSend(submitData).then(() => {
                setAlert({ showAlert: true, message: "Successfully reset your password.", severity: 'success', });
                setTimeout(() => {
                    window.location.replace(`${ADMIN_URL}`);
                }, 1300);
            }).catch((err) => {
                setAlert({ showAlert: true, message: "Invalid password reset.", severity: 'error', });
            })
        }
    }

    return (loading ? <Spinner /> :
        <Fragment>
            <Grid container className={styles.card}>
                <Item elevation={0} variant="outlined">
                    <Box sx={{ pt: 1, pb: 5, pl: 1, color: '#000000', fontSize: '2rem', fontWeight: 'bold', }} >
                        {'Password reset'}
                    </Box>
                    <Grid container justify='center' spacing={3}>
                        <Grid item md={12}>
                            <CardContent>
                                <Grid item container spacing={1} justify='center'>
                                    <Grid item xs={12} sm={12} md={12} xl={12}>
                                        <ReusableTextField
                                            autoComplete='new-password'
                                            label='New Password'
                                            name='password'
                                            type='password'
                                            error={passwordError === true || passwordValidate === true}
                                            helperText={passwordError === true ? 'Password is required!' : passwordValidate === true && 'Must be at least 8 characters'}
                                            onChange={handlePassword}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <ReusableButton sx={{ backgroundColor: '#263AF7', '&.MuiButtonBase-root:hover': { backgroundColor: '#263AF7', }, marginLeft: '8px' }}
                                    type='Submit' disabled={submitData.password === ''} onClick={handleSubmitData}>
                                    Reset
                                </ReusableButton>
                            </CardActions>
                        </Grid>
                    </Grid>
                    <div className={styles.box}>
                        <p className={styles.boxDetails}>COPYRIGHT © THE APOLLO
                            ALL RIGHTS RESERVED</p>
                    </div>
                    {alert.showAlert && (
                    <Grid item md={12}>
                        <Alert
                            severity={alert.severity}
                            onClose={() =>
                                setAlert({
                                    ...alert,
                                    showAlert: false,
                                })
                            }
                        >
                            {alert.message}
                        </Alert>
                    </Grid>
                )}
                </Item>
            </Grid>
        </Fragment>
    );
};

