import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Grid,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Stack,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { Link, useNavigate } from 'react-router-dom';

//icons
import { Edit } from '@mui/icons-material';

//components
import ReusableButton from '../../components/UI/Button';
import Modal from '../../components/UI/Modal'
import AdminRemoveModal from '../../components/UI/Modal'
import ReusableSelect from '../../components/UI/Select';
import Spinner from '../../components/UI/Spinner';
import { GetAdmins, GetMe, AdminRoleUpdate, AdminStatusUpdate } from '../../services/users';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'index',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'first_name',
    label: 'NAME',
  },
  {
    id: 'avatar',
    label: 'AVATAR',
    disabled: true,
  },
  {
    id: 'is_superAdmin',
    label: 'ADMIN ROLE',
    disabled: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, myAdminRole } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 700, fontSize: 16 }}
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {myAdminRole === 150 &&
          <TableCell sx={{ fontWeight: 700, fontSize: 16 }} >STATUS</TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { myAdminRole } = props;
  return (
    <Toolbar
      sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mt: 5 }}
    >
      <Typography
        sx={{ flex: '1 1 60%', fontWeight: 700, fontFamily: 'Montserrat', fontSize: 36, color: 'black' }}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Admins
      </Typography>
      {myAdminRole === 150 &&
        <Link to='/new-admin' style={{ textDecoration: 'none' }}>
          <ReusableButton variant='contained' startIcon={<Add />}
            sx={{
              backgroundColor: '#202E39',
              '&.MuiButtonBase-root:hover': {
                backgroundColor: '#202E39',
              },
            }}>
            CREATE ADMIN
          </ReusableButton>
        </Link>
      }
    </Toolbar>
  );
};

const AdminList = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [adminRemoveModal, setAdminRemoveModal] = useState(false);
  const [data, setData] = useState([]);
  const [myAdminRole, setMyAdminRole] = useState('');
  const [adminId, setAdminId] = useState('');
  const [adminRole, setAdminRole] = useState('');
  const [username, setUserName] = useState('');
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const [isAdmin, setIsAdmin] = useState('')
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    GetMe().then((myData) => {
      setMyAdminRole(myData.admin_role)
      GetAdmins().then(response => {
        addIndexToData(response);
      }).catch((err) => {
        setData([]);
        setPageLoading(false);
      })
    }).catch((err) => {
      navigate(`/`);
    })
  }, [navigate]);

  const addIndexToData = (response) => {
    if (response.data === null) {
      setData([]);
      setPageLoading(false);
    } else {
      let i = 1;
      let userData = [];
      response.data.forEach(element => {
        element = { ...element, 'index': i };
        userData.push(element);
        i++;
      });
      setData(userData);
      setPageLoading(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = (id, name, role) => {
    setAdminId(id);
    setUserName(name);
    setAdminRole(role);
    setOpenModal(true);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
    setUpdateButtonDisable(true);
  }
  const handleOnChangeRole = (event) => {
    setAdminRole(event.target.value);
    setUpdateButtonDisable(false);
  }
  const handleUpdateAdminRole = () => {
    AdminRoleUpdate(adminId, adminRole).then(() => {
      GetAdmins().then(response => {
        addIndexToData(response);
        setOpenModal(false);
        setUpdateButtonDisable(true);
      }).catch((err) => {
        setData([]);
      })
    })
  }

  const handleOpenAdminRemoveModal = (id, name, isAdmin) => {
    setAdminId(id);
    setUserName(name);
    setIsAdmin(isAdmin)
    setAdminRemoveModal(true);
  }
  const handleCloseAdminRemoveModal = () => {
    setAdminRemoveModal(false);
  }
  const handleUpdateAdminStatus = () => {
    let status = 0
    if (isAdmin === 1) {
      status = -1
    } else if (isAdmin === -1) {
      status = 1
    }
    AdminStatusUpdate(adminId, status).then(() => {
      GetAdmins().then(response => {
        addIndexToData(response);
        setAdminRemoveModal(false);
      }).catch((err) => {
        setData([]);
      })
    })
  }

  let roles = [
    { key: 1, value: 150, name: 'Super' },
    { key: 2, value: 100, name: 'Normal' }
  ]

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} md={10} lg={11} sx={{ ml: { md: 5, lg: 12 }, mr: { md: 4, lg: 6, xl: 10 }, }}>
        {pageLoading ? <Spinner /> :
          <>
            <EnhancedTableToolbar myAdminRole={myAdminRole} />
            <Item elevation={0} variant="outlined" sx={{ mt: 5, borderRadius: 3 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750, mt: 5 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                    myAdminRole={myAdminRole}
                  />
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow tabIndex={-1} key={index} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                            <TableCell id={labelId} align='left'>
                              {row.index}
                            </TableCell>
                            <TableCell><Stack spacing={1}>{row.first_name !== '' || row.last_name !== '' ? (
                              <div style={{ fontWeight: 600, fontSize: 16 }}>{row.first_name + ' ' + row.last_name}</div>
                            ) : (
                              <div style={{ fontWeight: 600, fontSize: 16 }}>-</div>
                            )}
                              <div style={{ fontWeight: 600, fontSize: 16, color: '#919191' }}>{row.Email.address}</div>
                            </Stack></TableCell>
                            <TableCell align='right'>
                              <Avatar alt='avatar' src={row.avatar_url} />
                            </TableCell>
                            <TableCell align='left'>
                              {myAdminRole === 150 ? (
                                <ReusableButton endIcon={<Edit />} sx={{ backgroundColor: '#263AF7', minWidth: '100px' }}
                                  onClick={() => {
                                    handleOpenModal(row.id, row.username, row.admin_role)
                                  }} >
                                  {row.admin_role === 150 ? 'Super' : 'Normal'}
                                </ReusableButton>
                              ) : myAdminRole === 100 ? (
                                <ReusableButton sx={{ backgroundColor: '#263AF7', minWidth: '100px' }}>
                                  {row.admin_role === 150 ? 'Super' : 'Normal'}
                                </ReusableButton>
                              ) : ''}
                            </TableCell>
                            {myAdminRole === 150 &&
                              <TableCell align='left'>
                                {row.is_dev === 1 &&
                                  <ReusableButton endIcon={<Edit />} size='small'
                                    sx={{
                                      backgroundColor: '#79CC0E',
                                      '&.MuiButtonBase-root:hover': {
                                        backgroundColor: '#9dde49'
                                      },
                                      minWidth: 106,
                                    }}
                                    onClick={() => {
                                      handleOpenAdminRemoveModal(row.id, row.username, row.is_dev)
                                    }}
                                  >
                                    Active
                                  </ReusableButton>
                                }
                                {row.is_dev === -1 &&
                                  <ReusableButton endIcon={<Edit />} size='small'
                                    sx={{
                                      backgroundColor: '#7c8fa1',
                                      '&.MuiButtonBase-root:hover': {
                                        backgroundColor: '#92A9BD',
                                      },
                                    }}
                                    onClick={() => {
                                      handleOpenAdminRemoveModal(row.id, row.username, row.is_dev)
                                    }}
                                  >
                                    Inactive
                                  </ReusableButton>
                                }

                              </TableCell>}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Item>
          </>
        }
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ mb: 3 }}>
          <div style={{ fontWeight: 600, marginRight: 5 }}>{username}</div>
        </Box>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <ReusableSelect title={'Admin Role'} menus={roles} value={adminRole} onChange={handleOnChangeRole} />
          </Grid>
        </Grid>
        <ReusableButton sx={
          {
            border: '1px solid #263af7',
            color: '#263af7',
            background: 'none',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              border: '1px solid #263af7',
            },
            marginRight: 2,
            marginTop: 3
          }
        } onClick={handleCloseModal} type='submit'>Cancel</ReusableButton>
        <ReusableButton sx={
          {
            backgroundColor: '#263AF7',
            '&.MuiButtonBase-root:hover': {
              backgroundColor: '#263AF7',
            },
            marginTop: 3
          }
        } type='submit' onClick={handleUpdateAdminRole} disabled={updateButtonDisable}>Update</ReusableButton>
      </Modal>

      <AdminRemoveModal open={adminRemoveModal} onClose={handleCloseAdminRemoveModal}>
        <Box sx={{ mb: 3 }}>
          <div style={{ fontWeight: 600, marginRight: 5 }}>
            Are you sure you want to {isAdmin === 1 ? 'deactivate' : isAdmin === -1 ? 'activate' : ''} {username} ?
          </div>
        </Box>
        <ReusableButton sx={
          {
            border: '1px solid #263af7',
            color: '#263af7',
            background: 'none',
            '&.MuiButtonBase-root:hover': {
              background: 'none',
              border: '1px solid #263af7',
            },
            marginRight: 2,
            marginTop: 3
          }
        } onClick={handleCloseAdminRemoveModal} type='submit'>Cancel</ReusableButton>
        {isAdmin === 1 ?
          <ReusableButton sx={
            {
              backgroundColor: '#ff0000',
              '&.MuiButtonBase-root:hover': {
                backgroundColor: '#bb0000',
              },
              marginTop: 3
            }
          } type='submit' onClick={handleUpdateAdminStatus}>Deactivate</ReusableButton>
          : isAdmin === -1 ?
            <ReusableButton sx={
              {
                backgroundColor: '#79CC0E',
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: '#9dde49'
                },
                marginTop: 3
              }
            }
              type='submit' onClick={handleUpdateAdminStatus}>Activate</ReusableButton>
            : ''}

      </AdminRemoveModal>
    </Grid>
  );
};

export default AdminList;
